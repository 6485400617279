<template>
  <v-dialog
    v-model="dialog"
    class="UnitsForm"
    width="768"
    @click:outside="handleCloseDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" small color="primary" dark v-bind="attrs" v-on="on">
        Add units<v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-form @submit.prevent="handleAddUnits">
        <v-card-title>
          <span class="text-h5"
            >Add units for {{ this.$route.query.paidWithCash ? 'invoice id' : 'contract id' }}: <strong>{{ item.id }}</strong></span
          >
        </v-card-title>

        <v-col cols="12" :md="units.length > 0 ? 9 : 12">
          <v-autocomplete
            :items="unitOptions"
            :loading="loading"
            :search-input.sync="search"
            item-text="vin_number"
            label="Search for a truck / trailer"
            placeholder="Search by vin or unit number"
            prepend-icon="mdi-database-search"
            item-disabled
            clearable
            hide-no-data
            hide-selected
            return-object
            @change="handleUnitPicked"
          >
            <template v-slot:item="{ item }">
              <v-icon class="mr-1"> {{ getIcon(item.type) }} </v-icon>VIN:&nbsp;
              <strong>{{ item.vin_number }}</strong>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col cols="12">
          <p class="ma-0 pa-0 pl-8">
            {{
              units.length > 0
                ? 'Selected units (click to remove):'
                : 'Pick units to add.'
            }}
          </p>
        </v-col>

        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="units"
            class="elevation-1"
          >
            <template v-slot:item.amount="{ item }">
              <v-text-field
                v-model="item.amount"
                label="Cash price"
                type="number"
                min="0"
              ></v-text-field>
            </template>
            <template v-slot:item.priceWithInterest="{ item }">
              <v-text-field
                  v-model="item.priceWithInterest"
                  label="Price with interest"
                  type="number"
                  min="0"
              ></v-text-field>
            </template>
            <template v-slot:item.monthlyPayment="{ item }">
              <v-text-field
                  v-model="item.monthlyPayment"
                  label="Monthly payment"
                  type="number"
                  min="0"
              ></v-text-field>
            </template>
            <template v-slot:item.action="{ item, index }">
              <v-btn icon @click="handleRemoveUnit(index)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseDialog"
            >Close</v-btn
          >
          <v-btn
            type="submit"
            color="blue darken-1"
            :disabled="!permissions.ACCOUNTING_EDIT_ALL || units.length === 0"
            text
            @click="handleAddUnits"
            >Add units</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import debounce from 'lodash/debounce'
import service from '../../services/ContractService'

export default {
  name: 'UnitsForm',

  props: ['item'],

  emits: ['submit-units'],

  created() {
    this.handleUnitTypePicked(this.item.unitsType)
  },

  data() {
    return {
      unitsAllowed: [],
      unitOptions: [],
      units: [],
      loading: false,
      dialog: false,
      queryTerm: '',
      headers: [
        { text: 'VIN', value: 'vin_number' },
        { text: 'Cash Price', value: 'amount' },
        { text: 'Price with interest', value: 'priceWithInterest' },
        { text: 'Monthly payment', value: 'monthlyPayment' },
        { text: 'Action', value: 'action', sortable: false },
      ],
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),
    search: {
      get() {
        return this.queryTerm
      },

      set(val) {
        if (val !== this.queryTerm) {
          this.queryTerm = val
          this.searchUnits(val)
        }
      },
    },
  },

  methods: {
    handleAddUnits() {
      this.$emit('submit-units', [...this.units])
      this.handleCloseDialog()
    },

    handleCloseDialog() {
      this.unitOptions = []
      this.units = []
      this.dialog = false
      this.queryTerm = ''
    },

    handleUnitTypePicked(value) {
      this.unitOptions = []

      switch (value) {
      case 'f-trucks':
      case 'rf-trucks':
      case 'c-trucks':
        this.unitsAllowed = ['trucks']
        break
      case 'f-trailers':
      case 'rf-trailers':
      case 'c-trailers':
        this.unitsAllowed = ['trailers']
        break
      case 'out-of-company':
        this.unitsAllowed = ['trucks', 'trailers']
        break
      case 'f-trucks-trailers':
      case 'rf-trucks-trailers':
        this.unitsAllowed = ['trucks', 'trailers']
        break
      case 'cars':
        this.unitsAllowed = ['cars']
        break

      default:
        this.unitsAllowed = []
        break
      }
    },

    getIcon(type) {
      switch (type) {
      case 'car':
        return 'mdi-car'
      case 'trailer':
        return 'mdi-truck-trailer'
      default:
        return 'mdi-truck'
      }
    },

    handleRemoveUnit(index) {
      this.units.splice(index, 1)
    },

    handleUnitPicked(u) {
      if (!u) return
      this.search = ''
      this.queryTerm = ''
      this.units.unshift(u)
    },

    createSearchQuery() {
      const query = {}

      for (const a of this.unitsAllowed) Object.assign(query, { [a]: 1 })

      const takenUnits = [...(this.item.units || []), ...(this.units || [])]

      if (takenUnits.length > 0)
        Object.assign(query, { not: takenUnits.map((u) => u.vin_number) })

      return query
    },

    searchUnits: debounce(async function (val) {
      if (val)
        try {
          this.loading = true
          console.log(this.createSearchQuery())
          const { data } = await service.searchUnits(
            val,
            this.createSearchQuery()
          )
          if (Array.isArray(data.docs)) this.unitOptions = data.docs
        } catch (error) {
          console.error('Contracts searchUnits()', error)
          this.unitOptions = []
          this.error = true
        } finally {
          this.loading = false
        }
    }, 200),
  },
}
</script>
