import Api from '@/services/Api'

export default {
  getContracts(bankId, params) {
    return Api().post(`/contracts/by-bank/${bankId}`, params)
  },
  addContract(body) {
    return Api().post('/contracts', body)
  },
  editContract(id, body) {
    return Api().put(`/contracts/${id}`, body)
  },
  getContract(id) {
    return Api().get(`/contracts/${id}`)
  },
  getUnits(params) {
    return Api().get('/contracts/units', { params })
  },
  deleteContract(id) {
    return Api().delete(`/contracts/${id}`)
  },
  terminate(id) {
    return Api().put(`/contracts/terminate/${id}`)
  },
  ready(id) {
    return Api().put(`/contracts/ready/${id}`)
  },
  payBalloon(id) {
    return Api().put(`/contracts/pay-balloon/${id}`)
  },
  editPaymentAmount(id, monthId, amount) {
    return Api().put(`/contracts/edit-payment-amount/${id}/${monthId}`, { amount })
  },
  editPayment(id, monthId, remove = false) {
    let url = `/contracts/edit-payment/${id}/${monthId}`
    if (remove) url += '?remove=1'
    return Api().put(url)
  },
  editNote(id, note) {
    return Api().put(`/contracts/edit-note/${id}`, { note })
  },
  editMonthNote(id, monthId, note) {
    return Api().put(`/contracts/edit-note/${id}/${monthId}`, { note })
  },
  editPayments(id, amount) {
    return Api().put(`/contracts/edit-payments/${id}`, { amount })
  },
  editPaymentsFromIndex(id, body) {
    return Api().put(`/contracts/edit-payments-from-index/${id}`, body)
  },
  payUnit(id, unitId, body) {
    return Api().put(`/contracts/pay-unit/${id}/${unitId}`, body)
  },
  getUnitFilterOptions(params) {
    return Api().get('/contracts/units/filter-options', { params })
  },
  searchUnits(val, query = {}) {
    let url = `/contracts/search/units/${val}`
    const queryString = new URLSearchParams(query).toString()
    url += `?${queryString}`
    return Api().get(url)
  },
  getHistory(contractId, type) {
    return Api().get(`/contracts/logs/${contractId}/${type}`)
  },

  addUnits(contractId, body) {
    return Api().post(`/contracts/add-units/${contractId}`, body)
  },
  editUnit(contractId, unitVin, unit) {
    return Api().put(`/contracts/edit-unit/${contractId}/${unitVin}`, { unit })
  },
  deleteUnit(contractId, unitVin) {
    return Api().delete(`/contracts/remove-unit/${contractId}/${unitVin}`)
  },

  terminatePaymentsFromIndex(id, index, body) {
    return Api().put(`/contracts/terminate-payments-from-index/${id}/${index}`, body)
  }, 
  terminatePayment(contractId, paymentId) {
    return Api().put(`/contracts/terminate-payment/${contractId}/${paymentId}`)
  },

  downloadSheet(params) {
    return Api().post('/contracts/export-units', params)
  },

  getUnitsHistory(params) {
    return Api().get('/contracts/units-logs/list', { params })
  },
  allUnitsList(params) {
    return Api().get('/contracts/all-units/list', { params })
  },

  getLeaseUnits(company, params) {
    return Api().get(`/contracts/lease-units/${company}`, params )
  },
  saveUnit(body, withMonthly) {
    return Api().put(`/contracts/accounting-lease-units?withMonthly=${withMonthly}`, body)
  },
  deleteLeaseUnit(id, type) {
    return Api().delete(`/contracts/accounting-lease-units/${id}/${type}`)
  },
  updateMonthlyPayments(body) {
    return Api().put('/contracts/accounting-lease-units/update-payments', body)
  },
  payAllForCurrentMonth(company) {
    return Api().post(`/contracts/lease-units/${company}/pay-all-for-current-month`)
  }
}
