<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="500"
    min-width="400"
    offset-x
    @update:model="onMenuOpened"
  >
    <template v-slot:activator="{ on }">
      <v-icon class="FieldHistory__button" small :color="hasHistory? 'teal' : ''" v-on="on"
        >mdi-history</v-icon
      >
      <slot />
    </template>

    <v-card>
      <div style="min-width: 500px; min-height: 200px">
        <v-progress-circular
          v-if="loading"
          indeterminate
          class="FieldHistory__loader"
          color="primary"
        ></v-progress-circular>

        <p class="FieldHistory__empty" v-else-if="!hasHistory">
          No field history.
        </p>

        <v-list v-else class="FieldHistory__list" style="max-height: 300px; overflow-y: auto;">
          <template v-for="item of data">
            <v-list-item :key="`units-field-history-${item._id}`">
              <v-list-item-content class="FieldHistory__content">
                <div class="FieldHistory__left">
                  <p class="FieldHistory__time">
                    Contract date: {{ formatTime(item.date) }}
                  </p>
                  <p class="FieldHistory__time">
                    Bank: {{ item.contract.bank.name }}
                  </p>
                  <router-link
                    class="FieldHistory__time"
                    :to="`/contract-details/${item.contractObjectId}`"
                  >
                    Contract: {{ item.contractId }}
                  </router-link>
                  <p class="FieldHistory__time">
                    {{ item.isDealer ? `Cash price: ${getCashPrice(item.contract)}` : `Price with interest: ${getPriceWithInterest(item.contract)}` }}
                  </p>
                  <p class="FieldHistory__time">
                    Date paid: {{ getPaidDate(item.contract) }}
                  </p>
                </div>

                <div class="FieldHistory__right">
                  <p class="FieldHistory__user">
                    {{ item.userEmail }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'
import service from '../../services/ContractService'
import bankService from '../../services/BanksService'


export default {
  name: 'UnitsFieldHistory',

  props: {
    vinNumber: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      menu: false,
      data: null,
      loading: false,
      options: { page: 1, itemsPerPage: 20 },
      filters: { vin_number: this.vinNumber },
      contracts: {},
    }
  },

  computed: {
    hasHistory() {
      if (!this.data) return false
      return Boolean(this.data.length)
    },
  },

  watch: {
    menu(val) {
      if (val === true && !this.data) {
        this.fetchData()
      }
    }
  },


  methods: {
    formatTime(v) {
      return moment(v).format('MM/DD/YYYY, h:mm:ss a')
    },

    getPriceWithInterest(contract) {
      const unit = contract.units.find(unit => unit.vin_number === this.vinNumber)
      return unit ? unit.priceWithInterest : 'Price not available'
    },

    getCashPrice(contract) {
      const unit = contract.units.find(unit => unit.vin_number === this.vinNumber)
      return unit ? unit.payoff : 'Price not available'
    },

    getPaidDate(contract) {
      const unit = contract.units.find(unit => unit.vin_number === this.vinNumber)
      return unit && unit.paid ? this.formatTime(unit.paid.date) : 'Not paid'
    },

    async checkIfDealerOrBank(bank) {
      try {
        const { data } = await bankService.getBank(bank._id)
        if (data.doc.is_dealer === true) {
          return true
        } else {
          return false
        }
      } catch (error) {
        console.error('Error fetching bank:', error)
        return false
      }
    },


    async fetchContract(contractObjectId) {
      if (this.contracts[contractObjectId]) {
        return this.contracts[contractObjectId]
      }
      try {
        const { data } = await service.getContract(contractObjectId)
        this.$set(this.contracts, contractObjectId, data)
        return data.doc
      } catch (error) {
        console.error('Error fetching contract', error)
        return null
      }
    },


    async fetchData() {
      this.loading = true
      try {
        const { data } = await service.getUnitsHistory({
          ...this.options,
          ...this.filters
        })
        this.data = data.docs

        await Promise.all(this.data.map(async (item) => {
          item.contract = await this.fetchContract(item.contractObjectId)
          item.isDealer = await this.checkIfDealerOrBank(item.contract.bank)
        }))
      } catch (error) {
        console.error('UnitsFieldHistory fetchData()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    onMenuOpened(isOpen) {
      if (isOpen && !this.data) {
        this.fetchData()
      }
    },
  },
}
</script>

<style lang="scss">
.FieldHistory {
  &__list {
    & > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  &__button {
    position: absolute !important;
    top: 0;
    right: 0;

    opacity: 0.6;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__left,
  &__right {
    flex: 1 !important;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__change {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6px !important;
  }

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }

  &__time,
  &__user {
    color: rgb(33, 150, 243);
    font-size: 11px;
    margin: 0 !important;
  }

  &__empty {
    padding: 16px;
    text-align: center;
  }
}
</style>
