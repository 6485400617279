import bank from '../../services/BanksService'

const state = {
  banks: []
}

const getters = {
  banks (state) {
    return state.banks
  }
}

const actions = {
  getBanks ({commit, dispatch}, payload) {
    console.log(payload)
    dispatch('common/setLoading', true, {root: true})
    return bank.getBanks(payload).then(res => {
      commit('setBanks', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getAllBanks ({commit, dispatch}) {
    dispatch('common/setLoading', true, {root: true})
    return bank.getAllDealerships().then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('setBanks', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addBank ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return bank.addBank(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addBank', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  editBank ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return bank.editBank(payload).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('editBank', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteBank ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return bank.deleteBank(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteBank', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  }
}

const mutations = {
  setBanks (state, banks) {
    state.banks = banks
  },
  addBank (state, bank) {
    const tmpBanks = state.banks.slice()
    tmpBanks.push(bank)
    state.banks = tmpBanks
  },
  editBank (state, bank) {
    let tmpBanks = state.banks.slice()
    let index = tmpBanks.findIndex(el => el._id === bank._id)
    tmpBanks.splice(index, 1, bank)
    state.banks = tmpBanks
  },
  deleteBank (state, id) {
    const tmpBanks = state.banks.slice()
    tmpBanks.splice(tmpBanks.findIndex(el => el._id === id), 1)
    state.banks = tmpBanks
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
