<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    max-width="400"
    min-width="400"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-icon class="FieldHistory__button" small :color="hasHistory? 'teal' : ''" @click="on.click"
        >mdi-history</v-icon
      >
      <slot />
    </template>

    <v-card>
      <div style="min-width: 400px; min-height: 200px">
        <v-progress-circular
          v-if="loading"
          indeterminate
          class="FieldHistory__loader"
          color="primary"
        ></v-progress-circular>

        <p class="FieldHistory__empty" v-else-if="!hasHistory">
          No field history.
        </p>

        <v-list v-else class="FieldHistory__list">
          <template v-for="item of data">
            <v-list-item :key="`field-history-${item._id}`">
              <v-list-item-content class="FieldHistory__content">
                <div class="FieldHistory__left">
                  <p class="FieldHistory__change">
                    Changed from
                    <strong>{{ getValue(item.oldState) }}</strong> to
                    <strong>{{ getValue(item.newState) }}</strong>
                  </p>

                  <p class="FieldHistory__time">
                    {{ formatTime(item.date) }}
                  </p>
                </div>

                <div class="FieldHistory__right">
                  <p class="FieldHistory__user">
                    {{ item.userEmail }}
                  </p>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import moment from 'moment'
import service from '../../services/ContractService'

export default {
  name: 'FieldHistory',

  props: {
    contractId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    itemValue: {
      type: String,
      required: false,
    },
    columnNameShowHistory: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      menu: false,
      data: null,
      loading: false,
    }
  },

  computed: {
    hasHistory() {
      if (!this.data) return false
      return Boolean(this.data.length)
    },
  },

  watch: {
    menu(v) {
      if (v) this.fetchData()
    },
  },

  mounted() {
    if (this.columnNameShowHistory === 'balloonPayment') {

      this.fetchData()
    }
  },

  methods: {
    formatTime(v) {
      return moment(v).format('MM/DD/YYYY, h:mm:ss a')
    },

    getValue(v) {
      if (this.itemValue && v) return v[this.itemValue] || 'no-value'
      return v || 'no-value'
    },

    async fetchData() {
      this.loading = true
      try {
        const { data } = await service.getHistory(this.contractId, this.type)
        if (Array.isArray(data.docs)) this.data = data.docs
      } catch (error) {
        console.error('FieldHistory fetchData()', error)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.FieldHistory {
  &__list {
    & > *:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  &__button {
    position: absolute !important;
    top: 0;
    right: 0;

    opacity: 0.6;
    transition: all 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__left,
  &__right {
    flex: 1 !important;
  }

  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__change {
    font-size: 11px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 6px !important;
  }

  &__loader {
    position: absolute;
    z-index: 100;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }

  &__time,
  &__user {
    color: rgb(33, 150, 243);
    font-size: 11px;
    margin: 0 !important;
  }

  &__empty {
    padding: 16px;
    text-align: center;
  }
}
</style>
