<template>
  <div class="AccountingUnits">
    <div class="d-flex align-center justify-space-between mb-2">
      <v-spacer />
      <table-filters
        class="mr-2"
        :initial-values="{ ...FILTERS_INITIAL }"
        :initial-form="filtersForm"
        @filters-changed="handleFiltersChanged"
        hide-clear-button
      />
    </div>
    <div>
      Units count: {{ totalItems }}
      <br />
      <br />
    </div>

    <div class="AccountingUnits__table">
      <div class="AccountingUnits__table-order">
        <table-order
          :userId="user && user._id"
          :default-order="deafultHeaders"
          @order-changed="handleTableOrderChanged"
          :table-name="'ALL_UNITS'"
        />
      </div>

      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :options="options"
        :footer-props="footerProps"
        :server-items-length="totalItems"
        @update:options="handleOptionsChange"
        disable-sort
        dense
        :item-class="getRowClass"
      >
        <template v-slot:item.type="{ item }">
          <td
            :style="{
              backgroundColor: getTypeBackground(item.type),
              textTransform: 'capitalize',
            }"
          >
            {{ item.type }}
          </td>
        </template>

        <template v-slot:item.make="{ item }">
          {{ typeof item.make === 'object' ? item.make.name : item.make }}
        </template>

        <template v-slot:item.model="{ item }">
          {{
            item.type === 'trailer'
              ? item.trailerType
              : item.model && item.model.name
          }}
        </template>

        <template v-slot:item.date="{ item }">
          <div class="text-overflow">
            {{ formatDate(item.date)}}
          </div>
        </template>

        <template v-slot:item.bank="{ item }">
          <div class="text-overflow">
            {{ item.lease ? '/' : item.bank && item.bank.name }}
          </div>
        </template>

        <template v-slot:item.financingType="{ item }">
          {{ getFinancingType(item) }}
        </template>

        <template v-slot:item.remainingPeriod="{ item }">
          <td>
            {{ getRemainingPeriod(item) }}
          </td>
        </template>

        <template v-slot:item.payoff="{ item }">
          <span v-if="item.lease">{{
            typeof getLeaseRemainingBalance(item) === 'number'
              ? `$${getLeaseRemainingBalance(item).toFixed(2)}`
              : getLeaseRemainingBalance(item)
          }}</span>
          <span v-else>
            {{
              typeof item.payoff === 'number'
                ? `$${item.payoff.toFixed(2)}`
                : item.payoff
            }}</span
          >
        </template>

        <template v-slot:item.location="{ item }">
          <div class="text-overflow">
            {{ item.yard && item.yard.name }}
          </div>
        </template>

        <template v-slot:item.contractName="{ item }">
          <span v-if="item.lease">/</span>
          <router-link
            v-else
            class="AccountingUnits__id-col text-overflow"
            :to="`/contract-details/${item.contractId}`"
          >
            {{ item.contractName }}
          </router-link>
        </template>

        <template v-slot:item.vin_number="{ item }">
          <units-field-history :vinNumber="item.vin_number" >
            <span>
              {{ item.vin_number }}
            </span>
          </units-field-history>
        </template>

        <template v-slot:item.company="{ item }">
          <div class="text-overflow">
            {{ item.lease ? item.lease.company : item.company }}
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          {{ item.status || (item.aStatus && item.aStatus.text) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteUnit(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>

    <div class="AccountingUnits__bottom-left">
      <v-btn
        color="success"
        :loading="loading"
        class="mr-1"
        dark
        small
        @click="handleExportToExcell"
      >
        Export <v-icon small>mdi-file-excel-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import { mapGetters } from 'vuex'
import TableFilters from '../TableFilters.vue'
import service from '../../services/ContractService'
import { b64toBlob, downloadFile } from '../../utils'
import TableOrder from '../TableOrder.vue'
import UnitsFieldHistory from './UnitsFieldHistory.vue'
import moment from 'moment'
import * as accountingMethods from '../../utils/accountingMethods'
import {
  FILTERS,
  FILTERS_INITIAL,
  HEADERS,
  COMMON_DATA,
} from '../../utils/accountingConstants'

export default {
  name: 'AccountingUnits',

  components: { TableFilters, TableOrder, UnitsFieldHistory },

  data() {
    return {
      ...COMMON_DATA,
      footerProps: {
        'items-per-page-options': [15, 30, 45],
      },
      options: { page: 1, itemsPerPage: 15 },
      headers: [...HEADERS.FINANCED_AND_REFINANCED],
      deafultHeaders: [...HEADERS.FINANCED_AND_REFINANCED],

      FILTERS_INITIAL,
      filters: { ...FILTERS_INITIAL },
      filtersForm: {
        ...FILTERS.COMMON_UNIT_FILTERS,
        ...FILTERS.ACCOUNTING_UNIT_FILTERS,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user', 'permissions']),
  },

  async created() {
    await this.setupFilters()
  },

  methods: {
    ...accountingMethods,

    getRowClass(item) {
      return (item.payoff === 'Paid in full.' || (item.lease && item.lease.paid === true)) ? 'red-row' : ''
    },

    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },

    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.fetchItems()
    },

    handleTableOrderChanged(order) {
      const prepareObj = {}
      order.forEach((c) => {
        prepareObj[c.columnName] = {
          order: c.order,
          isShown: c.isShown,
        }
      })
      const headers = this.deafultHeaders
      this.headers = headers
        .filter((c) => prepareObj[c.value].isShown)
        .sort((a, b) => prepareObj[a.value].order - prepareObj[b.value].order)
    },

    handleFiltersChanged: throttle(function (filters) {
      this.filters = { ...filters }
      this.options.page = 1
      this.items = []
      this.fetchItems()
    }, 200),

    getLeaseRemainingBalance(item) {
      if (item.lease.paid) return 'Paid in full.'
      return (
        this.getLeaseRemainingPeriod(item) * item.lease.monthlyPayment +
          item.lease.balloonPayment || 0
      )
    },

    getLeaseRemainingPeriod(item) {
      let remainingPeriod = 0
      for (let i = 0; i < item.lease.paymentPeriod; i++) {
        const date = moment(item.lease.date).add(i, 'months')
        if (date.isAfter(moment())) remainingPeriod++
      }
      return remainingPeriod
    },

    async setupFilters() {
      try {
        const { data } = await service.getUnitFilterOptions({ forUnits: true })

        this.filtersForm.make.options = [
          { header: 'Truck' },
          ...data.truckMakes,
          { header: 'Trailer' },
          ...data.trailerMakes,
        ]
        this.filtersForm.model.options = [
          { header: 'Truck' },
          ...data.truckModels,
          { header: 'Trailer' },
          ...data.trailerTypes,
        ]
        this.filtersForm.bank.options = [...data.banks]
        this.filtersForm.yard.options = [...data.yards]
      } catch (error) {
        console.error('AccountingUnits setupFilters()', error)
      }
    },

    getFinancingType(unit) {
      if (unit.lease) return 'Lease'
      if (this.isRefinanced(unit.unitsType)) return 'Refinanced'
      if (this.isForCash(unit.unitsType)) return 'Paid with cash'
      if (this.isFinanced(unit.unitsType)) return 'Financed'
      return '/'
    },

    async handleExportToExcell() {
      try {
        this.loading = true
        const params = { ...this.filters }
        const { data } = await service.downloadSheet(params)
        const blob = b64toBlob(
          data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        )
        downloadFile(blob, 'units')
      } catch (error) {
        console.error('handleExportToExcell()', error)
      } finally {
        this.loading = false
      }
    },

    async fetchItems() {
      this.loading = true
      try {
        const params = { ...this.options, ...this.filters }

        const { data } = await service.allUnitsList(params)
        if (Array.isArray(data.docs)) {
          this.items = data.docs
          this.totalItems = data.totalItems
        }
      } catch (error) {
        console.error('AccountingUnits fetchItems()', error)
      } finally {
        this.loading = false
      }
    },

    async deleteUnit(item) {
      let confVal = confirm(
        `Are you sure you want to delete unit: ${item.vin_number}?`
      )
      if (confVal) {
        try {
          if (item.lease)
            await service.deleteLeaseUnit(item.vin_number, item.type)
          else await service.deleteUnit(item.contractId, item.vin_number)
          this.fetchItems()
        } catch (error) {
          console.log('deleteUnit()', error)
        }
      }
    },
    getRemainingPeriod(item) {
      let remainingPeriod = 0
      for (let i = 0; i < item.paymentPeriod; i++) {
        const date = moment(item.date).add(i, 'months')
        if (date.isAfter(moment())) remainingPeriod++
      }
      return remainingPeriod
    },
  },
}
</script>

<style lang="scss">
.AccountingUnits {
  position: relative;

  &__bottom-left {
    position: absolute;
    bottom: 4px;
    left: 0;
  }

  &__table-order {
    position: absolute;
    right: -2px;
    top: -20px;
    z-index: 10;
  }

  .red-row {
    background-color: #f44336 !important;
  }

  .v-data-footer__select .v-select {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  table {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }

  .text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
</style>
