<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Leasing - H19</v-tab>
      <v-tab>Leasing - TEL</v-tab>
      <v-tab>Leasing - National Lease</v-tab>
    </v-tabs>

    <leasing
      :company="company.name"
      :with-monthly="company.withMonthly"
      :security-deposit="company.securityDeposit"
      :balloon="company.balloon"
      :nationalLease="company.nationalLease"
    />
  </div>
</template>

<script>
import Leasing from './Leasing.vue'

const COMPANIES = {
  0: { name: 'H19', withMonthly: true },
  1: { name: 'TEL', securityDeposit: true, balloon: false, withMonthly: true },
  2: { name: 'NATIONAL LEASE', withMonthly: true, nationalLease: true },
}

export default {
  name: 'AccountingLease',

  components: { Leasing },

  data() {
    return {
      tab: 0,
    }
  },

  computed: {
    company() {
      return COMPANIES[this.tab]
    },
  },
}
</script>
