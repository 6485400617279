<template>
  <div class="boards-wrapper">
    <v-card>
      <v-card-title>
        {{ !isDealer ? $t('other.banks') : $t('other.accounting.dealers') }}

        <v-divider class="mx-4" inset vertical></v-divider>

        <table-filter
          :initial-values="{ ...FILTERS_INITIAL }"
          :initial-form="filtersForm"
          @filters-changed="handleFiltersChanged"
          hide-clear-button
        />
        <v-spacer></v-spacer>
      </v-card-title>
      <v-data-table
        height="550"
        :disable-sort="true"
        :headers="headers"
        :options.sync="options"
        :server-items-length="totalItems"
        :items="banks"
        class="elevation-1"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 15, 25],
        }"
      >
        <template slot="headerCell" slot-scope="props">
          {{ $t('user.' + props.header.value) }}
        </template>
        <template v-slot:item.isSpecial="{ item }">
          {{ item.isSpecial ? 'YES' : 'NO' }}
        </template>
        <template v-slot:item.name="{ item }">
          <router-link :to="getItemLink(item, filters.vin)" style="font-weight: 700">
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:item.debt="{ item }">
          ${{ (item.debt) }}
        </template>
        <template v-slot:item.is_active="{ item }">
          {{ item.is_active ? 'YES' : 'NO' }}
        </template>
        <template v-slot:item.actions="{ item }">
          <td class="justify-center layout px-0">
            <v-icon :disabled="!permissions.ACCOUNTING_EDIT_ALL || loading" small class="mr-2" @click="editBank(item)">
              mdi-pencil
            </v-icon>
            <v-icon :disabled="!permissions.ACCOUNTING_EDIT_ALL || loading" small @click="deleteBank(item)"> mdi-delete </v-icon>
          </td>
        </template>
      </v-data-table>
      <v-fab-transition>
        <v-btn
          :disabled="!permissions.ACCOUNTING_EDIT_ALL"
          color="pink"
          dark
          absolute
          top
          right
          fab
          @click="formDialog = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <v-dialog v-model="formDialog" width="800px" persistent>
      <v-card>
        <form @submit.prevent="onAddBank" data-vv-scope="bank">
          <v-card-title class="lighten-4 py-4 title">
            {{ form._id ? 'Edit' : 'Add' }} {{ !isDealer ? 'bank' : 'dealer' }}
          </v-card-title>
          <v-container grid-list-sm class="pa-4">
            <v-layout row wrap>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="name"
                  v-model="form.name"
                  v-validate="'required'"
                  :error="errors.has('bank.name')"
                  :error-messages="errors.first('bank.name')"
                  name="name"
                  id="name"
                  :autofocus="true"
                  placeholder="Name"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="email"
                  v-model="form.email"
                  name="email"
                  id="email"
                  :autofocus="true"
                  placeholder="Email"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="contact_person"
                  v-model="form.contact_person"
                  name="contact_person"
                  id="contact_person"
                  :autofocus="true"
                  placeholder="Contact Person"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="phone_number"
                  v-model="form.phone_number"
                  name="phone_number"
                  id="phone_number"
                  :autofocus="true"
                  placeholder="Phone Number"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 align-center justify-space-between>
                <v-text-field
                  ref="note"
                  v-model="form.note"
                  name="note"
                  id="note"
                  :autofocus="true"
                  placeholder="Note"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-checkbox
                  v-model="form.is_active"
                  label="Is active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeForm">Cancel</v-btn>
            <v-btn
              color="info"
              :loading="loading"
              :disabled="!permissions.ACCOUNTING_EDIT_ALL || loading"
              type="submit"
              >{{ form._id ? 'Save' : 'Add' }}</v-btn
            >
          </v-card-actions>
        </form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import TableFilter from '../TableFilters.vue'
import { mapGetters, mapActions } from 'vuex'

const FILTERS_INITIAL = {
  vin: null,
}

export default {
  name: 'Banks',

  components: {
    TableFilter,
  },

  data() {
    return {
      FILTERS_INITIAL,
      filtersForm: {
        vin: { type: 'text', label: 'VIN#', min: 1 },
        bank_name: { type: 'text', label: this.$route.query.isDealer ? 'Dealer Name' : 'Bank Name', min: 1 },
      },
      filters: { ...FILTERS_INITIAL },

      options: {
        page: 1,
        sortBy: 'name',
        sortDesc: true,
        itemsPerPage: 10,
      },
      totalItems: 0,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Debt', value: 'debt' },
        { text: 'Email', value: 'email' },
        { text: 'Contact Person', value: 'contact_person' },
        { text: 'Phone Number', value: 'phone_number' },
        { text: 'Note', value: 'note' },
        { text: 'Is active', value: 'is_active' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      form: {
        _id: null,
        name: '',
        email: '',
        contact_person: '',
        phone_number: '',
        note: '',
        is_active: true,
      },
      isDealer: Boolean(this.$route.query.isDealer),
      formDialog: false,
      orderDialog: false,
    }
  },
  watch: {
    $route() {
      this.isDealer = Boolean(this.$route.query.isDealer)
      this.fetchItems()
    },
    options: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters('common', ['loading']),
    ...mapGetters('banks', ['banks']),
    ...mapGetters('auth', ['permissions']),
  },
  methods: {
    ...mapActions({
      setMessage: 'common/setMessage',
    }),

    getItemLink(item, vinFilter) {
      const routeParams = {
        path: `/contracts/${item._id}`,
        query: {}
      }

      if (vinFilter) {
        routeParams.query.vin = vinFilter
      }

      if (this.$route.query.isDealer) {
        routeParams.query.isPaidWithCash = true
      }
      return routeParams
    },

    fetchItems() {
      this.$store
        .dispatch('banks/getBanks', {
          ...this.options,
          ...this.filters,
          isDealer: Boolean(this.$route.query.isDealer),
        })
        .then((res) => {
          this.totalItems = res.total
          this.banks = res.docs.map(bank => ({
            ...bank,
            debt: bank.debt.toFixed(2),
          }))
        })
    },
    handleFiltersChanged: throttle(function (filters) {
      this.filters = { ...filters }
      this.options.page = 1
      this.fetchItems()
    }, 200),
    onAddBank() {
      this.$validator.validateAll('bank').then((result) => {
        if (result) {
          if (this.form._id) {
            this.$store
              .dispatch('banks/editBank', {
                ...this.form,
                is_dealer: Boolean(this.$route.query.isDealer),
              })
              .then((res) => {
                if (res) {
                  this.formDialog = false
                  this.form = {
                    _id: null,
                    name: '',
                    email: '',
                    contact_person: '',
                    note: '',
                    phone_number: '',
                    is_active: true,
                  }
                }
              })
              .catch((err) => {
                if (err.data && err.data.code === 11000) {
                  let key = Object.keys(err.data.keyPattern)[0]
                  this.errors.add({
                    scope: 'bank',
                    field: key,
                    msg: 'This field must be unique',
                  })
                }
              })
          } else {
            this.$store
              .dispatch('banks/addBank', {
                ...this.form,
                is_dealer: Boolean(this.$route.query.isDealer),
              })
              .then((res) => {
                if (res) {
                  this.formDialog = false
                  this.form = {
                    _id: null,
                    name: '',
                    email: '',
                    contact_person: '',
                    note: '',
                    phone_number: '',
                    is_active: true,
                  }
                }
              })
              .catch((err) => {
                if (err.data && err.data.code === 11000) {
                  let key = Object.keys(err.data.keyPattern)[0]
                  this.errors.add({
                    scope: 'bank',
                    field: key,
                    msg: 'This field must be unique',
                  })
                }
              })
              .finally(() => this.fetchItems())
          }
        }
      })
    },
    handleBankNameClicked(bankId) {
      this.$router.push(`/contracts/${bankId}`)
    },
    editBank(bank) {
      this.form = { ...bank }
      this.formDialog = true
    },
    deleteBank(bank) {
      let confVal = confirm(
        `Are you sure you want to delete bank: ${bank.name}?`
      )
      if (confVal) {
        this.$store
          .dispatch('banks/deleteBank', bank._id)
          .then(() => {
            this.setMessage(`${bank.name} successfully deleted`)
          })
          .catch((err) => {
            console.log('err on delete', err)
          })
      }
    },
    closeForm() {
      this.formDialog = false
      this.form = {
        _id: null,
        name: '',
        email: '',
        contact_person: '',
        note: '',
        phone_number: '',
        is_active: true,
      }
    },
  },
}
</script>

<style lang="scss">
.boards-wrapper {
  width: 100%;
  margin-top: 20px;
}

.v-list-item.move {
  cursor: move;

  .v-list-item__title.teamLead {
    color: green;
  }
}

</style>
