<template>
  <div class="Leasing">
    <div class="Leasing__actions">
      <table-filters
        :initial-values="FILTER_INITIAL"
        :initial-form="filtersForm"
        @filters-changed="handleFiltersChanged"
      />
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-menu
          ref="dateRangeMenu"
          v-model="dateRangeMenu"
          :close-on-content-click="false"
          :return-value.sync="dateRange"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-combobox
              v-model="dateRange"
              multiple
              chips
              small-chips
              label="Date range"
              prepend-icon="mdi-calendar"
              readonly
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="clearDateRange"
          ></v-combobox>
        </template>
        <v-date-picker
            v-model="dateRange"
            multiple
            no-title
            scrollable
            @input="handleDateRangeChanged"
        >
          <v-spacer></v-spacer>
          <v-btn
              text
              color="primary"
              @click="dateRangeMenu = false"
          >
            Cancel
          </v-btn>
          <v-btn
              :disabled="dateRange.length === 1"
              text
              color="primary"
              @click="handleDateRangePicked"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-btn color="primary" dark class="mb-2" @click="openNewUnitDialog">
        New unit
      </v-btn>
      <v-btn color="success" dark class="mb-2 ml-2" @click="payAllForCurrentMonth()">
        Pay all for current month
      </v-btn>
    </div>

    <div v-if="dateRange.length === 2" class="total-payments">
      <strong>Total Payments for this date range:</strong> ${{ totalPayments }}
    </div>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      disable-sort
      dense
    >
      <template v-slot:item.type="{ item }">
        <td
          :style="{
            backgroundColor: getTypeBackground(item.type),
            textTransform: 'capitalize',
          }"
        >
          {{ item.type }}
        </td>
      </template>

      <template v-slot:item.year="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ item.year }}
        </td>
      </template>

      <template v-slot:item.vin_number="{ item }">
        <td
          :class="{ 'Leasing--red': Boolean(item.lease.paid) }"
          @click="handleVinClicked(item)"
        >
          {{ item.vin_number }}
        </td>
      </template>

      <template v-slot:item.lease.paymentPeriod="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ item.lease.paymentPeriod }}
        </td>
      </template>

      <template v-slot:item.make="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ typeof item.make === 'object' ? item.make.name : item.make }}
        </td>
      </template>

      <template v-slot:item.model="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.type === 'trailer'
              ? item.trailerType
              : item.model && item.model.name
          }}
        </td>
      </template>

      <template v-slot:item.monthlyPayment="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.lease.monthlyPayment &&
            `$${item.lease.monthlyPayment.toFixed(2)}`
          }}
        </td>
      </template>

      <template v-slot:item.balloonPayment="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.lease.balloonPayment &&
            `$${item.lease.balloonPayment.toFixed(2)}`
          }}
        </td>
      </template>

      <template v-slot:item.securityDeposit="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{
            item.lease.securityDeposit &&
            `$${item.lease.securityDeposit.toFixed(2)}`
          }}
        </td>
      </template>

      <template v-slot:item.date="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ formatDate(item.lease.date) }}
        </td>
      </template>

      <template v-slot:item.datePaidOn="{ item }">
        <td
          v-if="item.lease.datePaidOn"
          :class="{ 'Leasing--red': Boolean(item.lease.paid) }"
        >
          Paid in full: {{ formatDate(item.lease.datePaidOn) }}
        </td>
      </template>

      <template v-slot:item.amount="{ item }">
        <td
          v-if="item.lease.datePaidOn"
          :class="{ 'Leasing--red': Boolean(item.lease.paid) }"
        >
          {{ item.lease.amount}}
        </td>
      </template>

      <template v-slot:item.remainingPeriod="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ getRemainingPeriod(item) }}
        </td>
      </template>

      <template v-slot:item.remainingBalance="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          ${{ getRemainingBalance(item).toFixed(2) }}
        </td>
      </template>

      <template v-slot:item.dueDate="{ item }">
        <td :class="{ 'Leasing--red': Boolean(item.lease.paid) }">
          {{ getDueDate(item) }}
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      @click:outside="closeDelete"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog"
      max-width="540px"
      @click:outside="handleCloseDialog"
    >
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-card-title v-if="editId" class="text-h5"
                >Edit VIN: {{ form.vin_number }}</v-card-title
              >

              <v-col v-if="!editId" cols="12">
                <v-autocomplete
                  :items="unitOptions"
                  :loading="loading"
                  :search-input.sync="search"
                  item-text="vin_number"
                  label="Search for a truck / trailer / car"
                  placeholder="Search by vin or unit number"
                  prepend-icon="mdi-database-search"
                  item-disabled
                  clearable
                  hide-no-data
                  hide-selected
                  return-object
                  v-model="form.vin_number"
                  @change="handleUnitPicked"
                >
                  <template v-slot:item="{ item }">
                    <v-icon class="mr-1"> {{ getIcon(item.type) }} </v-icon
                    >VIN:&nbsp;
                    <strong>{{ item.vin_number }}</strong>
                    <template v-if="item.number"
                      >&nbsp;Number:&nbsp;
                      <strong>{{ item.number }}</strong></template
                    >
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-menu
                  ref="dateMenu"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.date"
                      label="Date*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="form.date"
                    @input="
                      (v) => {
                        $refs.dateMenu.save(v);
                        dateMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.monthlyPayment"
                  prepend-icon="mdi-clock"
                  type="number"
                  min="0"
                  label="Monthly*"
                />
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="form.paymentPeriod"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Payment period*"
                />
              </v-col>

              <v-col v-if="balloon" cols="6">
                <v-text-field
                  v-model="form.balloonPayment"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Balloon payment"
                />
              </v-col>

              <v-col v-if="securityDeposit" cols="6">
                <v-text-field
                  v-model="form.securityDeposit"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Security deposit"
                />
              </v-col>

              <v-col v-if="withMonthly" cols="6">
                <v-menu
                  ref="firstPaymentDateMenu"
                  v-model="firstPaymentDateMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.firstPaymentDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.firstPaymentDate"
                      label="First payment date*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="form.firstPaymentDate"
                    @input="
                      (v) => {
                        $refs.firstPaymentDateMenu.save(v);
                        firstPaymentDateMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>

              <v-col cols="6">
                  <v-checkbox
                    v-model="form.pastPaymentsPaid"
                    label="Set past months as paid"
                  />
              </v-col>

              <v-col cols="6">
                <v-checkbox
                  v-model="form.paid"
                  @change="handlePaidChange"
                  label="Is paid in full?"
                />
              </v-col>

              <v-col v-if="form.paid" cols="6">
                <v-menu
                  ref="datePaidOnMenu"
                  v-model="datePaidOnMenu"
                  :close-on-content-click="false"
                  :return-value.sync="form.datePaidOn"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.datePaidOn"
                      label="Date paid on*"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    :value="form.datePaidOn"
                    @input="
                      (v) => {
                        $refs.datePaidOnMenu.save(v);
                        datePaidOnMenu = false;
                      }
                    "
                    no-title
                    scrollable
                  />
                </v-menu>
              </v-col>
              <v-col v-if="form.paid" cols="6">
                <v-text-field
                  v-model="form.amount"
                  prepend-icon="mdi-currency-usd"
                  type="number"
                  min="0"
                  label="Amount"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseDialog">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="submitDisabled"
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="monthlyDialog"
      max-width="540px"
      @click:outside="handleCloseMonthlyDialog"
    >
      <v-card v-if="monthlyUnit">
        <v-card-title class="text-h5"
          >Edit payments for: {{ monthlyUnit.vin_number }}</v-card-title
        >

        <v-card-text style="max-height: 300px; overflow-y: scroll">
          <v-container>
            <v-data-table
              :headers="paymentsHeaders"
              :items="monthlyUnit.lease.monthlyPayments"
              :items-per-page="-1"
              hide-default-footer
              dense
            >
              <template v-slot:item.number="{ item, index }">
                <td style="text-align: center; font-weight: bold" :class="{ 'Leasing--red': item.paid }">
                  {{ index + 1 }}
                </td>
              </template>

              <template v-slot:item.dueDate="{ item }">
                <td style="text-align: center" :class="{ 'Leasing--red': item.paid }">
                  {{ item.dueDate | formatDate }}
                </td>
              </template>

              <template v-slot:item.amount="{ item }">
                <td style="text-align: center" :class="{ 'Leasing--red': item.paid }">
                  <v-text-field
                      v-model="item.amount"
                      class="Leasing__center-input"
                      type="number"
                      min="0"
                      hide-details
                      dense
                  />
                </td>
              </template>
              <template v-slot:item.paid="{ item }">
                <td style="text-align: center" :class="{ 'Leasing--red': item.paid }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="togglePaidUnpaid(item)"
                        class="mr-2"
                        small
                        :color="item.paid ? 'green' : ''"
                        style="cursor: pointer;"
                      >
                        mdi-currency-usd
                      </v-icon>
                    </template>
                    <span>{{ item.paid ? 'Unpay' : 'Pay' }}</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseMonthlyDialog">
            Cancel
          </v-btn>
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="updateUnit"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import debounce from 'lodash/debounce'
import service from '../../services/ContractService'
import trucService from '../../services/TruckService'
import trailerMarksService from '../../services/TrailerMarksService'
import trailerTypesService from '../../services/TrailerTypesService'
import { mapGetters } from 'vuex'
import store from '@/store'
import TableFilters from '../TableFilters.vue'

const DEFAULT_ITEM = {
  vin_number: null,
  number: null,
  type: null,
  year: null,
  make: null,
  model: null,
  trailerType: null,
  paid: false,
  datePaidOnMenu: false,

  monthlyPayment: null,
  paymentPeriod: null,
  balloonPayment: null,
  securityDeposit: null,
  date: null,
  firstPaymentDate: null,
  amount: null,
  pastPaymentsPaid: false,
}

const defaultHeaders = (balloon, securityDeposit) => [
  {
    text: 'Truck/Trailer',
    value: 'type',
  },
  { text: 'Date', value: 'date' },
  { text: 'VIN', value: 'vin_number' },
  { text: 'Make', value: 'make' },
  { text: 'Model', value: 'model' },
  { text: 'Year', value: 'year' },
  { text: 'Monthly Payment', value: 'monthlyPayment' },
  { text: 'Payment Period', value: 'lease.paymentPeriod' },

  ...(balloon ? [{ text: 'Balloon Payment', value: 'balloonPayment' }] : []),
  ...(securityDeposit
    ? [{ text: 'Security Deposit', value: 'securityDeposit' }]
    : []),
  { text: 'Remaining period', value: 'remainingPeriod' },
  { text: 'Remaining balance', value: 'remainingBalance' },
  { text: 'Due Date', value: 'dueDate' },
  { text: 'Paid in full', value: 'datePaidOn' },
  { text: 'Amount paid', value: 'amount' },
  { text: 'Actions', value: 'actions', width: 60, align: 'center' },
]

const PAYMENTS_HEADERS = [
  {
    align: 'center',
    sortable: false,
    text: '#',
    value: 'number',
    width: 40,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Due date',
    value: 'dueDate',
    width: 100,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Amount',
    value: 'amount',
    width: 60,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Paid',
    value: 'paid',
    width: 60,
  },
]

const NATIONAL_LEASE_HEADERS = [
  {
    text: 'Truck/Trailer',
    value: 'type',
  },
  { text: 'VIN', value: 'vin_number' },
  { text: 'Make', value: 'make' },
  { text: 'Model', value: 'model' },
  { text: 'Year', value: 'year' },
  { text: 'Pickup Date', value: 'date' },
  { text: 'Monthly Payment', value: 'monthlyPayment' },
  { text: 'Remaining Period', value: 'remainingPeriod' },
  { text: 'Remaining Balance', value: 'remainingBalance' },
  { text: 'Due Date', value: 'dueDate' },
  { text: 'Paid in full', value: 'datePaidOn' },
  { text: 'Amount paid', value: 'amount' },
  { text: 'Actions', value: 'actions', width: 60, align: 'center' },
]

const FILTER_INITIAL = {
  search: null,
  make: null,
  model: null,
  year: null,
}

const FILTERS_FORM = {
  search: { type: 'text', label: 'VIN', min: 1 },
  make: { type: 'select', label: 'Make', options: [] },
  model: { type: 'select', label: 'Model', options: [] },
  year: { type: 'text', label: 'Year' },
}

const REQUIRED = ['vin_number', 'date', 'paymentPeriod', 'monthlyPayment']

export default {
  name: 'Leasing',

  props: {
    company: {
      type: String,
      required: true,
    },
    withMonthly: {
      type: Boolean,
      default: false,
    },
    securityDeposit: {
      type: Boolean,
      default: false,
    },
    balloon: {
      type: Boolean,
      default: true,
    },
    nationalLease: {
      type: Boolean,
      default: false,
    },
  },

  components: { TableFilters },

  data() {
    return {
      FILTER_INITIAL,
      filtersForm: { ...FILTERS_FORM },
      paymentsHeaders: PAYMENTS_HEADERS,
      dateMenu: false,
      datePaidOnMenu: false,
      firstPaymentDateMenu: false,
      dialog: false,
      loading: false,
      unitOptions: [],
      dialogDelete: false,
      items: [],
      totalItems: 0,
      queryTerm: '',
      editId: null,
      filters: [],
      dateRange: [],
      options: {
        page: 1,
        itemsPerPage: 30,
      },
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50],
      },
      form: { ...DEFAULT_ITEM, company: this.company },
      monthlyDialog: false,
      monthlyUnit: false,
      dateRangeMenu: false,
    }
  },

  watch: {
    company(v) {
      this.fetchItems()
      this.form.company = v
    },
  },

  mounted() {
    this.setMakes(),
    this.setModels()
  },

  computed: {
    ...mapGetters('auth', ['permissions']),

    headers() {
      return this.nationalLease
        ? NATIONAL_LEASE_HEADERS
        : defaultHeaders(this.balloon, this.securityDeposit)
    },

    submitDisabled() {
      if (!this.permissions.ACCOUNTING_EDIT_ALL) return true
      for (const field of REQUIRED) if (!this.form[field]) return true
      return false
    },

    search: {
      get() {
        return this.queryTerm
      },

      set(val) {
        if (val !== this.queryTerm) {
          this.queryTerm = val
          this.searchUnits(val)
        }
      },
    },

    totalPayments() {
      return this.items.reduce((total, item) => {
        if (item.lease && item.lease.monthlyPayment) {
          return total + this.getRemainingBalance(item)
        }
        return total
      }, 0).toFixed(2)
    },
  },

  methods: {

    handleDateRangeChanged(v) {
      if (v.length === 2) {
        this.dateRangeMenu = false
        this.$refs.dateRangeMenu.save(v)
        this.handleFiltersChanged()
      }
    },

    clearDateRange() {
      this.dateRange = []
      this.handleFiltersChanged()
    },

    handleDateRangePicked() {
      this.$refs.dateRangeMenu.save(this.dateRange)
      this.handleFiltersChanged()
    },

    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.fetchItems()
    },

    handleCloseDialog() {
      this.unitOptions = []
      this.dialog = false
      this.queryTerm = ''
      this.resetForm()
    },

    openNewUnitDialog() {
      this.dialog = true
    },
    async payAllForCurrentMonth() {
      this.loading = true

      try {
        await service.payAllForCurrentMonth(this.company).then(res => {
          if (res.status === 200) {
            this.fetchItems()
            store.dispatch('common/setMessage', 'All payments for this month paid!')
          }
        })
      } catch (error) {
        console.error('Pay all for current month', error)
      } finally {
        this.loading = false
      }
    },

    handleFiltersChanged: debounce(async function (filters) {
      this.filters = filters
      this.items = []
      await this.fetchItems()
    }, 200),
    
    handleUnitPicked(u) {
      if (!u) return
      this.search = ''
      this.queryTerm = ''
      this.form = {
        ...this.form,
        ...u,
      }
    },

    getRemainingBalance(item) {
      if(item.lease.paid){
        return 0
      }
      let secDepo = 0
      if (item.lease.securityDeposit) {
        secDepo = item.lease.securityDeposit
      }
      return (
        this.getRemainingPeriod(item) * item.lease.monthlyPayment +
          item.lease.balloonPayment + secDepo || 0
      )
    },

    handlePaidChange(v) {
      if (!v) this.form.datePaidOn = null
      else this.form.datePaidOn = moment(new Date()).format('MM/DD/YYYY')
    },

    getDueDate(item) {
      if(item.lease.monthlyPayments.length > 0) { 
        return moment(item.lease.monthlyPayments[item.lease.monthlyPayments.length - 1].dueDate)
          .format('MM/DD/YYYY')
      }
    },

    handleCloseMonthlyDialog() {
      this.monthlyDialog = false
      this.monthlyUnit = null
    },

    handleVinClicked(item) {
      if (!this.withMonthly) return
      this.monthlyDialog = true
      this.monthlyUnit = { ...item }
    },

    async updateUnit() {
      this.loading = true

      try {
        await service.updateMonthlyPayments(this.monthlyUnit)
        await this.fetchItems()
        this.handleCloseMonthlyDialog()
      } catch (error) {
        console.error('Contracts updateUnit()', error)
      } finally {
        this.loading = false
      }
    },

    editItem(item) {
      const { _id, lease, ...rest } = item
      this.editId = _id
      this.form = Object.assign(
        {},
        {
          ...rest,
          ...lease,
          date: this.formatForDatePicker(lease.date),
          firstPaymentDate: lease.monthlyPayments.length > 0 ? this.formatForDatePicker(lease.monthlyPayments[0].dueDate) : ''
        }
      )
      this.dialog = true
    },

    deleteItem(item) {
      const { _id, lease, datePaidOn, ...rest } = item
      this.editId = _id
      this.form = Object.assign(
        {},
        {
          ...rest,
          ...lease,
          date: this.formatForDatePicker(lease.date),
          datePaidOn: this.formatForDatePicker(datePaidOn),
        }
      )
      this.dialogDelete = true
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    resetForm() {
      this.form = Object.assign({}, { ...DEFAULT_ITEM, company: this.company })
      this.editId = null
    },

    async save() {
      this.loading = true

      try {
        await service.saveUnit(this.form, this.withMonthly)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('Contracts save()', error)
      } finally {
        this.loading = false
      }
    },

    formatForDatePicker(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    getRemainingPeriod(item) {
      let remainingPeriod = 0
      for (let i = 0; i < item.lease.monthlyPayments.length; i++) {
        if(item.lease.monthlyPayments.length > 0){ 
          const date = moment(item.lease.monthlyPayments[i].dueDate)
          if (date.isAfter(moment()) && item.lease.monthlyPayments[i].paid === null) remainingPeriod++
        }
      }
      return remainingPeriod
    },

    closeDelete() {
      this.resetForm()
      this.dialogDelete = false
    },

    searchUnits: debounce(async function (val) {
      if (val)
        try {
          this.loading = true
          const { data } = await service.searchUnits(val, {
            trucks: 1,
            trailers: 1,
          })
          if (Array.isArray(data.docs)) this.unitOptions = data.docs
        } catch (error) {
          console.error('Leasing searchUnits()', error)
          this.unitOptions = []
        } finally {
          this.loading = false
        }
    }, 200),

    getIcon(type) {
      switch (type) {
      case 'car':
        return 'mdi-car'
      case 'trailer':
        return 'mdi-truck-trailer'
      default:
        return 'mdi-truck'
      }
    },

    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },

    getTypeBackground(type) {
      let color = '#ffffff'
      if (type === 'truck') return '#D8EAD3'
      if (type === 'trailer') return '#FFF2CC'
      return color
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteLeaseUnit(this.form.vin_number, this.form.type)
        await this.fetchItems()
      } catch (error) {
        console.error('Leasing deleteItemConfirm()', error)
      } finally {
        this.loading = false
        this.closeDelete()
      }
    },

    async setMakes() {
      const [truckResponse, trailerResponse] = await Promise.all([
        trucService.getAllTruckMake(),
        trailerMarksService.getAllTrailerMarks({ page: 1, itemsPerPage: 100 })
      ])

      const truckData = truckResponse.data
      const trailerData = trailerResponse.data.trailerMarks

      if (truckData && trailerData) {
        const combinedData = [...truckData, ...trailerData]
        this.filtersForm.make.options = combinedData.map(item => item.name)
      }
    },

    async setModels() {
      const [truckResponse, trailerResponse] = await Promise.all([
        trucService.getTruckModel({ page: 1, itemsPerPage: 100 }),
        trailerTypesService.getAllTrailerTypes({ page: 1, itemsPerPage: 100 })
      ])

      const truckData = truckResponse.data.docs
      const trailerData = trailerResponse.data.trailerTypes

      if (truckData && trailerData) {
        const combinedData = [...truckData, ...trailerData]
        this.filtersForm.model.options = combinedData.map(item => item.name)
      }
    },

    async fetchItems() {
      this.loading = true
      try {

        const params = {
          ...this.options,
          filters: JSON.stringify(this.filters),
          dateRange: Array.isArray(this.dateRange) && this.dateRange.length > 0 ? this.dateRange : []
        }

        const { data } = await service.getLeaseUnits(this.company, { params })
        if (Array.isArray(data.docs)) {
          this.items = data.docs
          this.totalItems = data.total
        }
      } catch (error) {
        console.error('Leasing fetchItems()', error)
      } finally {
        this.loading = false
      }
    },
    togglePaidUnpaid(item) {
      this.monthlyUnit.lease.monthlyPayments.forEach(payment => {
        if (payment._id === item._id) {
          payment.paid = payment.paid ? null : new Date().toISOString()
        }
      })
    }
  },
}
</script>

<style lang="scss">
.total-payments {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
}

.Leasing {
  position: relative;

  &__center-input input {
    text-align: center;
  }

  &--red {
    background: #ee4b2b;
  }

  &--green {
    background: #32cd32;
  }

  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 36px;
    padding: 0;
  }

  &__actions {
  display: flex;
  align-items: center;
  top: -40px;
  right: 0;
  height: 36px;
  
  @media (min-width: 1800px) {
    position: absolute;
  }
}


  table {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }
}
</style>
