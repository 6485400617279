<template>
  <div class="ContractDetails">
    <div class="ContractDetails__container">
      <div class="ContractDetails__details">
        <v-card :loading="loading">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-if="contract">
            <v-btn text color="primary" @click="$router.go(-1)">
              <v-icon left> mdi-chevron-left </v-icon>Back to contracts
            </v-btn>

            <v-card-title
              class="mb-0a py-0"
              :style="{
                backgroundColor: contract.terminated ? '#F44336' : 'white',
                color: contract.terminated ? 'white' : 'black',
              }"
              >
              {{ this.$route.query.paidWithCash ? 'Invoice ID' : 'Contract ID' }} : {{ contract.id }}
              {{
                contract.terminated
                  ? ' - TERMINATED'
                  : !contract.ready
                  ? ' - NOT READY'
                  : ''
              }}</v-card-title
            >

            <v-card-text>
              <div class="text-subtitle-1 mb-2">
                Contract date: {{ formatDate(contract.date) }}
              </div>

              <v-divider class="mx-4 mb-2"></v-divider>

              <template v-if="!isForCash">
                <div>
                  Principal amount: ${{ contract.principalAmount.toFixed(2) }}
                </div>
                <div>Payment period: {{ contract.paymentPeriod }}</div>
                <div>Due date: {{ contract.dueDate | formatDate }}</div>
                <div>Interest rate: {{ contract.interest }}%</div>
                <div>Down payment: ${{ contract.downPayment || 0 }}</div>
                <div>
                  Balloon payment: ${{
                    (contract.balloonPayment &&
                      contract.balloonPayment.amount) ||
                    0
                  }}
                </div>
                <div>
                  Balloon payment paid by: {{
                    (contract.balloonPayment &&
                      contract.balloonPayment.paid) ||
                    0
                  }}
                </div>
                <div>
                  Dat security deposit: ${{ contract.datSecurityDeposit || 0 }}
                </div>
                <div>Interest: ${{ interest }}</div>
                <div>Total amount: ${{ totalAmount }}</div>
                <div>
                  Remaining debt: ${{ contract.owningBalance.toFixed(2) }}
                </div>
                <div v-if="contract.terminatedDate">
                  Terminated date: {{ contract.terminatedDate | formatDate }}
                </div>
              </template>

              <template v-else>
                <div>Cash price: ${{ contract.cashPrice.toFixed(2) }}</div>
                <div>Down payment: ${{ contract.downPayment || 0 }}</div>
              </template>

              <v-divider class="mx-4 my-2"></v-divider>

              <template v-if="bank">
                <div>Bank: {{ bank.name }}</div>
                <div>Contact person: {{ bank.contact_person }}</div>
                <div>Phone No: {{ bank.phone_number }}</div>
                <div>Email: {{ bank.email }}</div>
                <v-divider class="mx-4 my-2"></v-divider>
              </template>

              <v-row
                @click="handleOpenNoteDialog"
                class="ma-0 d-flex align-stretch"
                style="cursor: pointer"
              >
                <v-text-field
                  v-model="contract.note"
                  label="Note"
                  append-icon="mdi-pencil"
                  hide-details
                  disabled
                />
              </v-row>

              <v-btn
                :disabled="!permissions.ACCOUNTING_EDIT_ALL"
                v-if="!contract.terminated"
                color="red"
                class="mt-5 mr-2"
                small
                @click="terminateDialog = true"
              >
                Terminate
              </v-btn>

              <v-btn
                :disabled="!permissions.ACCOUNTING_EDIT_ALL"
                v-if="!contract.ready"
                color="blue"
                dark
                class="mt-5"
                small
                @click="readyDialog = true"
              >
                Set as ready
              </v-btn>
            </v-card-text>
          </template>
        </v-card>
      </div>

      <div v-if="!isForCash" class="ContractDetails__payments">
        <div
          v-if="contract && contract.balloonPayment"
          class="d-flex align-center justify-space-between mb-1"
        >
          <p class="caption ma-0">
            Balloon Payment: ${{ contract.balloonPayment.amount }}
          </p>        
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            v-if="!contract.balloonPayment.paid"
            small
            color="primary"
            dark
            @click="balloonDialog = true"
          > 
            Pay balloon
          </v-btn>
          <p v-else class="caption ma-0">
            Paid on {{ formatDate(contract.balloonPayment.paid) }}<br>
            Paid by {{ contract.balloonPayment.paidBy }}
          </p>
        </div>

        <v-select
            v-model="monthlyPaymentsFilter"
            :items="monthlyPaymentsFilterItems"
            @change="(v) => monthlyPaymentsFilterChanged(v)"
            label="Monthly Payments Filter"
            solo
        ></v-select>
        <v-data-table
          :headers="paymentsHeaders"
          :loading="loading"
          :items="filteredMonthlyPayments"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:item.number="{ item, index }">

            <td
            :class="{
              'ContractDetails__border-bottom': shouldApplyBorder(item)
            }"
            style="text-align: center; font-weight: bold">
              {{ monthlyPayments.indexOf(item) + 1 }}
            </td>
          </template>

          <template v-slot:item.amount="{ item, index }">
            <td
              style="text-align: center"
              :class="{
                'ContractDetails--green': Boolean(item.paid),
                'ContractDetails--red': item.terminated,
                'ContractDetails__border-bottom': shouldApplyBorder(item)
              }"
            >
              {{ item.amount && `$${item.amount.toFixed(2)}` }}
            </td>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <template v-if="item.note !== 'Balloon Payment'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="
                      !permissions.ACCOUNTING_EDIT_ALL || item.terminated
                    "
                    class="mr-2"
                    small
                    @click="handlePaymentClicked({ ...item, index: index + 1 })"
                    color="blue"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-currency-usd
                  </v-icon>
                </template>
                <span>{{ item.paid ? 'Unpay' : 'Pay' }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="
                      !permissions.ACCOUNTING_EDIT_ALL ||
                      Boolean(item.paid) ||
                      item.terminated
                    "
                    class="mr-2"
                    small
                    @click="handleOpenAmountDialog(item, index)"
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pen
                  </v-icon>
                </template>
                <span>Edit payment.</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="
                      !permissions.ACCOUNTING_EDIT_ALL || Boolean(item.paid)
                    "
                    small
                    @click="handleOpenTerminatePaymentDialog(item, index)"
                    color="red"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-minus-box-outline
                  </v-icon>
                </template>
                <span>{{
                  item.terminated ? 'Unterminate payment' : 'Terminate payment'
                }}</span>
              </v-tooltip>
            </template>
          </template>

          <template v-slot:item.dueDate="{ item, index }">
            <td
            :class="{
              'ContractDetails__border-bottom': shouldApplyBorder(item)
            }"
            style="text-align: center">
              {{ item.dueDate === null ? 'Balloon Payment' : formatDate(item.dueDate) }}
            </td>
          </template>

          <template v-slot:item.note="{ item, index }">
            <td
            :class="{
              'ContractDetails__border-bottom': shouldApplyBorder(item)
            }"
              style="text-align: center"
              @click="handleOpenCommentDialog({ ...item, index })"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-message</v-icon>
                </template>
                <span style="display: block; max-width: 360px">
                  {{ item.note || 'Click to add note.' }}
                </span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </div>

      <div class="ContractDetails__units" v-if="contract">
        <div class="d-flex justify-end mb-1">
          <units-form :item="contract" @submit-units="handleSubmitUnits" />
        </div>

        <v-data-table
          :headers="unitsHeaders"
          :loading="loading"
          :items="contract.units"
          :items-per-page="-1"
          hide-default-footer
          dense
        >
          <template v-slot:item.type="{ item }">
            <v-icon>{{ getIcon(item.type) }}</v-icon>
          </template>

          <template v-slot:item.vin_number="{ item }">
            <router-link :to="getVinLink(item)">
              <strong>{{ item.vin_number }}</strong>
            </router-link>
          </template>

          <template v-slot:item.remainingBalance="{ item }"> 
            <td
              style="text-align: center"
              :class="{
                'ContractDetails--red': Boolean(item.paid),
                'ContractDetails--orange': Boolean(item.refinanced),
              }"
            >
              {{
                typeof item.payoff === 'number'
                  ? `$${((item.monthlyPayment * monthlyPaymentsRemaining) + balloonPerUnit).toFixed(2)}`
                  : item.payoff
              }}
            </td>
          </template>

          <template v-slot:item.monthlyPayment="{ item }">
            <td
              style="text-align: center"
              :class="{
                'ContractDetails--red': Boolean(item.paid),
                'ContractDetails--orange': Boolean(item.refinanced),
              }"
            >
              {{
                item.monthlyPayment ? `$${item.monthlyPayment.toFixed(2)}` : '/'
              }}
            </td>
          </template>

          <template v-slot:item.priceWithInterest="{ item }">
            <td
              style="text-align: center"
              :class="{
                'ContractDetails--red': Boolean(item.paid),
                'ContractDetails--orange': Boolean(item.refinanced),
              }"
            >
              {{
                item.priceWithInterest
                  ? `$${(item.priceWithInterest + balloonPerUnit).toFixed(2)}`
                  : balloonPerUnit
                  ? `$${balloonPerUnit.toFixed(2)}`
                  : '/'
              }}
            </td>
          </template>

          <template v-slot:item.paymentsRemaining="{ item }">
            <td
              style="text-align: center"
              :class="{
                'ContractDetails--red': Boolean(item.paid),
                'ContractDetails--orange': Boolean(item.refinanced),
              }"
            >
              {{ monthlyPaymentsRemaining }}
            </td>
          </template>

          <template v-slot:item.amount="{ item }">
            <td
              style="text-align: center"
              :class="{
                'ContractDetails--red': Boolean(item.paid),
                'ContractDetails--orange': Boolean(item.refinanced),
              }"
            >
              {{ item.amount ? `$${item.amount.toFixed(2)}` : '/' }}
            </td>
          </template>

          <template v-slot:item.note="{ item, index }">
            <td
              style="text-align: center"
              @click="handleOpenUnitCommentDialog({ ...item, index })"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">mdi-message</v-icon>
                </template>
                <span style="display: block; max-width: 360px">
                  {{ item.note || 'Click to add note.' }}
                </span>
              </v-tooltip>
            </td>
          </template>

          <template v-slot:item.actions="{ item, index }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="
                    !permissions.ACCOUNTING_EDIT_ALL ||
                    Boolean(item.paid) ||
                    item.refinanced
                  "
                  color="green"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="handleUnitAmountClicked({ ...item, index })"
                  >mdi-pen</v-icon
                >
              </template>
              <span style="display: block; max-width: 360px"> Edit unit </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="
                    !permissions.ACCOUNTING_EDIT_ALL || Boolean(item.paid)
                  "
                  color="orange"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="handleOpenUnitRefinanceDialog(item)"
                  >mdi-checkbox-outline</v-icon
                >
              </template>
              <span style="display: block; max-width: 360px">
                {{
                  !item.refinanced ? 'Set as refinanced' : 'Remove refinanced'
                }}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="
                    !permissions.ACCOUNTING_EDIT_ALL || item.refinanced
                  "
                  color="blue"
                  class="mr-2"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="handleOpenUnitPayDialog(item)"
                  >mdi-currency-usd</v-icon
                >
              </template>
              <span style="display: block; max-width: 360px">
                {{ !item.paid ? 'Pay unit' : 'Unpay unit' }}
              </span>
            </v-tooltip>

            <v-icon
              :disabled="
                !permissions.ACCOUNTING_EDIT_ALL ||
                Boolean(item.paid) ||
                item.refinanced
              "
              color="red"
              small
              @click="handleDeleteUnit(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </div>

    <!-- Payment comment dialog -->
    <v-dialog
      v-model="commentDialog"
      max-width="540px"
      @click:outside="handleCloseCommentDialog"
    >
      <v-card v-if="editPayment">
        <v-card-title class="text-h5"
          >{{ editPayment.isEdit ? 'Edit' : 'Add' }} note for payment number
          {{ editPayment.index }}</v-card-title
        >

        <v-card-text>
          <v-text-field
            v-model="editPayment.note"
            label="Note"
            @keyup.enter="handleEditComment"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseCommentDialog"
            >Close</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handleEditComment"
            >{{ editPayment.isEdit ? 'Edit' : 'Add' }} note</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Unit comment dialog -->
    <v-dialog
      v-model="commentUnitDialog"
      max-width="540px"
      @click:outside="handleCloseUnitCommentDialog"
    >
      <v-card v-if="editUnit">
        <v-card-title class="text-h5"
          >{{ editUnit.isEdit ? 'Edit' : 'Add' }} note for unit
          {{ editUnit.vin_number }}</v-card-title
        >

        <v-card-text>
          <v-text-field
            v-model="editUnit.note"
            label="Note"
            @keyup.enter="handleEditUnitComment"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="handleCloseUnitCommentDialog"
            >Close</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handleEditUnitComment"
            >{{ editUnit.isEdit ? 'Edit' : 'Add' }} note</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Amount dialog -->
    <v-dialog
      v-model="amountDialog"
      max-width="540px"
      @click:outside="handleCloseAmountDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> Set amount </v-card-title>

        <v-card-text>
          <v-text-field
            v-model.number="newAmount"
            prepend-icon="mdi-currency-usd"
            label="New amount"
            min="0"
            type="number"
            hide-details
            small
          />

          <v-checkbox
            v-model="changeFollowingPayments"
            label="Edit all payments after this one"
            hide-details
            small
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseAmountDialog"
            >Close</v-btn
          >
          <v-btn
            color="blue darken-1"
            :disabled="!permissions.ACCOUNTING_EDIT_ALL || !newAmount"
            text
            @click="handleEditAmount"
            >Save</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Terminate payment dialog -->
    <v-dialog
      v-model="terminatePaymentDialog"
      max-width="540px"
      @click:outside="handleCloseTerminatePaymentDialog"
    >
      <v-card v-if="terminatePayment">
        <v-card-title class="text-h5">
          {{ terminatePayment.terminated ? 'Unterminate' : 'Terminate' }}
          payment number {{ editPaymentIndex + 1 }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            :value="terminatePayment.amount.toFixed(2)"
            prepend-icon="mdi-currency-usd"
            disabled
            label="Amount"
          />
          <v-checkbox
            v-if="!terminatePayment.terminated"
            v-model="changeFollowingPayments"
            label="Edit all payments after this one"
            hide-details
            small
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="handleCloseTerminatePaymentDialog"
            >Close</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handleTerminatePayments"
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Unit dialog. Edit amount or pay -->
    <v-dialog
      v-model="unitDialog"
      max-width="540"
      @click:outside="handleCloseUnitsDialog"
    >
      <v-card v-if="editUnit">
        <v-card-title class="text-h5"
          >Edit unit: {{ editUnit.vin_number }}</v-card-title
        >
        <v-card-text>
          <v-text-field
            v-model.number="editUnit.amount"
            class="mb-5"
            prepend-icon="mdi-currency-usd"
            label="Cash price"
            min="0"
            type="number"
            hide-details
            small
          />

          <v-text-field
            v-if="!isForCash"
            v-model.number="editUnit.priceWithInterest"
            class="mb-5"
            prepend-icon="mdi-currency-usd"
            label="Price with interest"
            min="0"
            type="number"
            hide-details
            small
          />

          <v-text-field
            v-if="!isForCash"
            v-model.number="editUnit.monthlyPayment"
            class="mb-5"
            prepend-icon="mdi-currency-usd"
            label="Monthly payment"
            min="0"
            type="number"
            hide-details
            small
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseUnitsDialog"
            >Close</v-btn
          >
          <v-btn
            color="blue darken-1"
            :disabled="
              !permissions.ACCOUNTING_EDIT_ALL || Boolean(editUnit.paid)
            "
            text
            @click="handleSaveEditUnit"
            >Edit amount</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Monthly payment dialog -->
    <v-dialog
      v-model="paymentDialog"
      max-width="540px"
      @click:outside="handleClosePaymentDialog"
    >
      <v-card v-if="editPayment">
        <v-card-title class="text-h5"
          >Edit payment number {{ editPayment.index }}</v-card-title
        >

        <v-card-text>
          <v-alert v-if="isOverdue(editPayment)" type="warning"
            >Overdue!</v-alert
          >

          <v-row>
            <v-col cols="6">
              <v-text-field
                :value="formatDate(editPayment.dueDate)"
                disabled
                label="Due date"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="editPayment.amount.toFixed(2)"
                disabled
                label="Amount"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="monthlyPaymentsRemaining"
                disabled
                label="Monthly payments remaining"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="contract.owningBalance.toFixed(2)"
                disabled
                label="Remaining debt"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleClosePaymentDialog"
            >Close</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="toggleInstallmentPaid"
            >{{ editPayment.paid ? 'Set as unpaid' : 'Set as paid' }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Contract note edit dialog -->
    <v-dialog
      v-model="noteDialog"
      max-width="540px"
      @click:outside="handleCloseNoteDialog"
    >
      <v-card v-if="contract">
        <v-card-title />

        <v-card-text>
          <v-text-field
            v-model="editNote"
            label="Note"
            @keyup.enter="handleEditNote"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseNoteDialog"
            >Close</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handleEditNote"
            >{{ contract.note ? 'Edit' : 'Add' }} note</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete unit dialog -->
    <v-dialog
      v-model="deleteUnitDialog"
      max-width="500px"
      @click:outside="closeDeleteUnits"
    >
      <v-card>
        <v-card-title v-if="deleteUnit" class="text-h5 text-center"
          >Remove {{ deleteUnit.vin_number }}?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteUnits"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="deleteUnitConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Terminate dialog -->
    <v-dialog
      v-model="terminateDialog"
      max-width="600px"
      @click:outside="terminateDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5 text-center"
          >Are you sure you want to terminate this contract?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="terminateDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handleTerminate"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Terminate dialog -->
    <v-dialog
      v-model="readyDialog"
      max-width="600px"
      @click:outside="readyDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5 text-center"
          >Are you sure you finished setting up initial data?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="readyDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handlyReady"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Balloon dialog -->
    <v-dialog
      v-model="balloonDialog"
      max-width="500px"
      @click:outside="balloonDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5 text-center"
          >Are you sure you want to pay balloon for this contract?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="balloonDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handlePayBalloon"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Unit refinance dialog dialog -->
    <v-dialog
      v-model="unitRefinanceDialog"
      max-width="480px"
      @click:outside="handleCloseUnitRefinanceDialog"
    >
      <v-card v-if="unitRefinance">
        <v-card-title class="text-h5 text-center">{{
          unitRefinance.refinanced
            ? 'Are you sure you want to remove refinanced status on this unit?'
            : 'Are you sure you want to refinance this unit?'
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="handleCloseUnitRefinanceDialog"
            >Cancel</v-btn
          >
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="blue darken-1"
            text
            @click="handleRefinanceUnit"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Unit pay dialog dialog -->
    <v-dialog
      v-model="unitPayDialog"
      max-width="500px"
      @click:outside="handleCloseUnitPayDialog"
    >
      <v-card v-if="editUnit">
        <v-card-title class="text-h5 text-center">{{
          editUnit.isPaid ? 'Unit unpay' : 'Unit payoff'
        }}</v-card-title>

        <v-card-text>
          <template v-if="!editUnit.isPaid">
            <v-text-field
              v-if="isForCash"
              :value="editUnit.amount.toFixed(2)"
              prepend-icon="mdi-currency-usd"
              disabled
              label="Unit payoff price"
            />
            <v-text-field
              v-else
              :value="
                typeof editUnit.payoff === 'number'
                  ? `$${editUnit.payoff.toFixed(2)}`
                  : editUnit.payoff
              "
              prepend-icon="mdi-currency-usd"
              disabled
              label="Unit payoff price (balloon per unit included)"
            />

            <v-menu
              ref="payUnitMenu"
              v-model="payUnitMenu"
              :close-on-content-click="false"
              :return-value.sync="editUnit.paid"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editUnit.paid"
                  label="Paid on"
                  prepend-icon="mdi-calendar"
                  :messages="
                    editUnit.paid ? null : 'Pick a date to payoff unit'
                  "
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :value="editUnit.paid"
                @input="
                  (v) => {
                    payUnitMenu = false;
                    $refs.payUnitMenu.save(v);
                  }
                "
                :allowed-dates="isDateAllowed"
                no-title
                scrollable
              />
            </v-menu>
          </template>
          <template v-else>
            <v-text-field
              :value="editUnit.paid.amount.toFixed(2)"
              prepend-icon="mdi-currency-usd"
              label="Amount paid off"
              disabled
            />
            <v-text-field
              :value="formatDate(editUnit.paid.date)"
              label="Paid on"
              prepend-icon="mdi-calendar"
              disabled
            />
          </template>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseUnitPayDialog"
            >Cancel</v-btn
          >
          <v-btn
            type="submit"
            color="blue darken-1"
            :disabled="disallowPayUnit"
            text
            @click="handlePayUnit"
            >{{ editUnit.isPaid ? 'Unpay unit' : 'Pay off unit' }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import service from '../../services/ContractService'
import UnitsForm from './UnitsForm.vue'
import bankServvice from '../../services/BanksService'

const PAYMENTS_HEADERS = [
  {
    align: 'center',
    sortable: false,
    text: '#',
    value: 'number',
    width: 40,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Due date',
    value: 'dueDate',
    width: 100,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Amount',
    value: 'amount',
    width: 100,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Note',
    value: 'note',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Actions',
    value: 'actions',
  },
]

const UNITS_HEADERS = [
  {
    align: 'center',
    sortable: false,
    text: 'Type',
    value: 'type',
    width: 40,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Vin number',
    value: 'vin_number',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Cash price',
    value: 'amount',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Price with interest',
    value: 'priceWithInterest',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Monthly Payment',
    value: 'monthlyPayment',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Remaining payments',
    value: 'paymentsRemaining',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Remaining balance',
    value: 'remainingBalance',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Note',
    value: 'note',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Actions',
    value: 'actions',
    width: 110,
  },
]

const CASH_UNITS_HEADERS = [
  {
    align: 'center',
    sortable: false,
    text: 'Type',
    value: 'type',
    width: 40,
  },
  {
    align: 'center',
    sortable: false,
    text: 'Vin number',
    value: 'vin_number',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Cash price',
    value: 'amount',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Note',
    value: 'note',
  },
  {
    align: 'center',
    sortable: false,
    text: 'Actions',
    value: 'actions',
    width: 110,
  },
]

const DEFAULT_ITEM = {
  id: null,
  file: null,
  date: moment().format('YYYY-MM-DD'),
  bank: null,
  company: null,
  principalAmount: null,
  units: [],
  interest: null,
  paymentPeriod: 60,
  downPayment: null,
  note: null,
  unitsType: null,
  isConvoy: false,
}

const time = (date) => new Date(date).getTime()

export default {
  name: 'ContractDetails',

  components: { UnitsForm },

  data() {
    return {
      terminateDialog: false,
      readyDialog: false,
      balloonDialog: false,
      terminatePaymentDialog: false,
      terminatePayment: false,
      unitRefinanceDialog: false,
      unitRefinance: null,
      unitPayDialog: false,
      unitPay: null,

      error: null,
      loading: false,
      monthlyPayments: [],
      contract: null,
      bank: null,

      unitDialog: false,
      editUnit: null,
      editUnitIndex: null,
      payUnitMenu: false,

      paymentsHeaders: PAYMENTS_HEADERS,
      unitsHeaders: this.$route.query.paidWithCash
        ? CASH_UNITS_HEADERS
        : UNITS_HEADERS,

      editPayment: null,
      editNote: null,
      commentDialog: false,
      commentUnitDialog: false,
      noteDialog: false,
      paymentDialog: false,
      deleteUnitDialog: false,
      deleteUnit: null,

      amountDialog: false,
      newAmount: null,
      changeFollowingPayments: false,
      editPaymentIndex: null,

      editId: null,
      form: { ...DEFAULT_ITEM },
      quartalPayment: false,
      monthlyPaymentsFilterItems: ['All monthly payments', 'Paid', 'Unpaid', 'Overdue'],
      monthlyPaymentsFilter: 'All monthly payments'
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),

    contractId() {
      return this.$route.params.id
    },

    interest() {
      const { principalAmount, totalAmount } = this.contract
      return (totalAmount - principalAmount).toFixed(2)
    },

    totalAmount() {
      if (!this.contract) return null
      const { totalAmount, downPayment, balloonPayment } = this.contract

      return (
        totalAmount +
        (downPayment || 0) +
        ((balloonPayment && balloonPayment.amount) || 0)
      ).toFixed(2)
    },

    monthlyPaymentsRemaining() {
      if(this.contract.balloonPayment && this.contract.balloonPayment.paid === null) {
        // uslov provere jel postoji balloon posto ako postoji dodaje se u rate kao poslednja rata a onda broj rata za monthly nije dobar
        return this.contract.monthlyPayments.filter(
          (m) => !m.paid && !m.terminated
        ).length - 1 
      } else {
        return this.contract.monthlyPayments.filter(
          (m) => !m.paid && !m.terminated
        ).length
      }
    },

    balloonPerUnit() {
      if (!this.contract.balloonPayment) return 0
      return this.contract.balloonPayment.amount / this.contract.units.length
    },

    isForCash() {
      if (!this.contract) return false
      return ['c-trailers', 'c-trucks'].includes(this.contract.unitsType)
    },

    disallowPayUnit() {
      if (!this.permissions.ACCOUNTING_EDIT_ALL) return true
      if (this.editUnit.isPaid) return false
      if (this.isForCash) return !this.editUnit.amount
      return typeof this.editUnit.payoff !== 'number'
    },

    filteredMonthlyPayments() {
      const now = new Date()
      return this.monthlyPayments.filter(payment => {
        if (this.monthlyPaymentsFilter === 'Paid') {
          if(this.contract.balloonPayment) {
            return payment.paid !== null
          } else {
            return payment.paid !== null 
          }
        } else if (this.monthlyPaymentsFilter === 'Unpaid') {
          if(this.contract.balloonPayment) {
            return payment.paid === null
          } else {
            return payment.paid === null
          }
        } else if (this.monthlyPaymentsFilter === 'Overdue') {
          // provera jer ballon payment nema dueDate
          return payment.dueDate ? payment.paid === null && new Date(payment.dueDate) < now : false
        } else {
          return true
        }
      })
    }
  },

  async created() {
    await this.fetchItem()
    this.quartalPayment = this.contract.quartalPayment
  },

  methods: {

    shouldApplyBorder(item) {
      return item.quartal === true
    },

    handleOpenUnitRefinanceDialog(item) {
      this.unitRefinanceDialog = true
      this.unitRefinance = { ...item }
    },

    handleCloseUnitRefinanceDialog() {
      this.unitRefinanceDialog = false
      this.unitRefinance = null
    },

    handleOpenUnitPayDialog(item) {
      this.unitPayDialog = true
      this.editUnit = { ...item, isPaid: Boolean(item.paid) }
    },

    handleCloseUnitPayDialog() {
      this.unitPayDialog = false
      this.editUnit = null
    },

    async handleRefinanceUnit() {
      this.loading = true
      try {
        await service.editUnit(this.contractId, this.unitRefinance.vin_number, {
          ...this.unitRefinance,
          refinanced: !this.unitRefinance.refinanced,
        })
        this.fetchItem()
        this.handleCloseUnitRefinanceDialog()
      } catch (error) {
        console.warn('handleRefinanceUnit()', error)
      } finally {
        this.loading = false
      }
    },

    async fetchItem() {
      this.loading = true
      try {
        const { data } = await service.getContract(this.contractId)
        if (data.doc) {
          const { monthlyPayments, balloonPayment, ...rest } = data.doc
          if (Array.isArray(monthlyPayments)){
            this.monthlyPayments = monthlyPayments
          }

          if (balloonPayment && balloonPayment.amount) {
            this.monthlyPayments.push({
              amount: balloonPayment.amount,
              dueDate: null,
              note: 'Balloon Payment',
              paid: balloonPayment.paid ? true : null,
              terminated: false
            })
          }
          this.contract = { ...rest, monthlyPayments, balloonPayment }

          const { data: bankData } = await bankServvice.getBank(rest.bank._id)
          this.bank = bankData.doc
          this.unitsHeaders = bankData.doc.is_dealer
            ? CASH_UNITS_HEADERS
            : UNITS_HEADERS
        }
      } catch (error) {
        console.error('ContractDetails fetchItem()', error)
        this.error = error
      } finally {
        this.loading = false
      }
    },

    closeDeleteUnits() {
      this.deleteUnit = null
      this.deleteUnitDialog = false
    },

    handleDeleteUnit({ _id, vin_number }) {
      this.deleteUnit = { _id, vin_number }
      this.deleteUnitDialog = true
    },

    isDateAllowed(v) {
      const now = new Date()
      const value = new Date(v)
      if (now.getTime() > value.getTime()) return true
      return value.getDate() <= now.getDate()
    },

    async deleteUnitConfirm() {
      const { _id } = this.contract
      this.loading = true
      try {
        await service.deleteUnit(_id, this.deleteUnit.vin_number)
        this.fetchItem()
        this.deleteUnit = null
        this.deleteUnitDialog = null
      } catch (error) {
        console.warn('handleSubmitUnits()', error)
      } finally {
        this.loading = false
      }
    },

    async handleSubmitUnits(newUnits) {
      const { _id } = this.contract
      this.loading = true
      try {
        await service.addUnits(_id, { units: newUnits })
        this.fetchItem()
      } catch (error) {
        console.warn('handleSubmitUnits()', error)
      } finally {
        this.loading = false
      }
    },

    async handleSaveEditUnit() {
      const { _id } = this.contract
      this.loading = true
      try {
        await service.editUnit(_id, this.editUnit.vin_number, this.editUnit)
        this.fetchItem()
        this.handleCloseUnitsDialog()
      } catch (error) {
        console.warn('handleSubmitUnits()', error)
      } finally {
        this.loading = false
      }
    },

    isOverdue({ dueDate, paid }) {
      return !paid && time(dueDate) < Date.now()
    },

    async handleTerminate() {
      try {
        this.loading = true
        await service.terminate(this.contract._id)
        this.fetchItem()
        this.terminateDialog = false
      } catch (error) {
        console.error('ContractDetails handleTerminate()', error)
      } finally {
        this.loading = false
      }
    },

    formatDate(date) {
      return this.$options.filters.formatDateObj(date, 'MM/DD/YYYY')
    },

    handleClosePaymentDialog() {
      this.editPayment = null
      this.paymentDialog = false
    },

    handlePaymentClicked(item) {
      this.editPayment = item
      this.paymentDialog = true
    },

    handleCloseCommentDialog() {
      this.commentDialog = false
      this.editPayment = null
    },

    async handleTerminateSingleAmount() {
      this.loading = true
      try {
        await service.terminatePayment(
          this.contract._id,
          this.singleAmountMonthId
        )
        this.fetchItem()
        this.handleCloseSingleAmountDialog()
      } catch (error) {
        console.warn('handleTerminateSingleAmount()', error)
      } finally {
        this.loading = false
      }
    },

    handleOpenCommentDialog(item) {
      this.editPayment = { ...item, isEdit: Boolean(item.note) }
      this.commentDialog = true
    },

    handleOpenUnitCommentDialog({ index, ...rest }) {
      this.editUnit = { ...rest }
      this.editUnitIndex = index
      this.commentUnitDialog = true
    },

    handleCloseUnitCommentDialog() {
      this.commentUnitDialog = false
      this.editUnit = null
      this.editUnitIndex = null
    },

    handleCloseAmountDialog() {
      this.amountDialog = false
      this.editPaymentIndex = null
      this.changeFollowingPayments = false
      this.newAmount = null
    },

    handleOpenAmountDialog(item, index) {
      this.amountDialog = true
      this.editPaymentIndex = index
      this.newAmount = item.amount
    },

    handleCloseTerminatePaymentDialog() {
      this.terminatePaymentDialog = false
      this.terminatePayment = null
      this.changeFollowingPayments = false
      this.editPaymentIndex = null
    },

    handleOpenTerminatePaymentDialog(item, index) {
      this.terminatePaymentDialog = true
      this.terminatePayment = { ...item }
      this.editPaymentIndex = index
    },

    async handleTerminatePayments() {
      this.loading = true
      try {
        await service.terminatePaymentsFromIndex(
          this.contractId,
          this.editPaymentIndex,
          {
            changeFollowingPayments: this.changeFollowingPayments,
            remove: this.terminatePayment.terminated,
          }
        )
        await this.fetchItem()
        this.handleCloseTerminatePaymentDialog()
      } catch (error) {
        console.error('ContractDetails handleTerminatePayments()', error)
      } finally {
        this.loading = false
      }
    },

    async handleEditAmount() {
      this.loading = true
      try {
        await service.editPaymentsFromIndex(this.contractId, {
          amount: this.newAmount,
          index: this.editPaymentIndex,
          changeFollowingPayments: this.changeFollowingPayments,
        })
        await this.fetchItem()
        this.handleCloseAmountDialog()
      } catch (error) {
        console.error('ContractDetails handleEditAmount()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async handleEditNote() {
      this.loading = true
      try {
        await service.editNote(this.contractId, this.editNote)
        await this.fetchItem()
        this.handleCloseNoteDialog()
      } catch (error) {
        console.error('ContractDetails handleEditNote()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    getIcon(type) {
      switch (type) {
      case 'car':
        return 'mdi-car'
      case 'trailer':
        return 'mdi-truck-trailer'
      default:
        return 'mdi-truck'
      }
    },

    handleOpenNoteDialog() {
      this.editNote = this.contract.note
      this.noteDialog = true
    },

    handleCloseNoteDialog() {
      this.editNote = null
      this.noteDialog = false
    },

    async handleEditComment() {
      this.loading = true
      try {
        const { _id: monthId, note } = this.editPayment
        await service.editMonthNote(this.contractId, monthId, note)
        await this.fetchItem()
        this.handleCloseCommentDialog()
      } catch (error) {
        console.error('ContractDetails handleEditComment()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    async handleEditUnitComment() {
      this.handleSaveEditUnit()
      this.fetchItem()
      this.handleCloseUnitCommentDialog()
    },

    getVinLink({ type, vin_number }) {
      switch (type) {
      case 'truck':
        return `/trucks/all?search=${vin_number}`
      default:
        return `/${type}s?search=${vin_number}`
      }
    },

    async toggleInstallmentPaid() {
      this.loading = true
      try {
        const { _id: monthId, paid } = this.editPayment
        await service.editPayment(this.contractId, monthId, paid)
        await this.fetchItem()
        this.handleClosePaymentDialog()
      } catch (error) {
        console.error('ContractDetails toggleInstallmentPaid()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    handleUnitAmountClicked({ index, ...rest }) {
      if (rest.paid) return
      this.unitDialog = true
      this.editUnit = { ...rest }
      this.editUnitIndex = index
    },

    handleCloseUnitsDialog() {
      this.unitDialog = false
      this.editUnit = null
      this.editUnitIndex = null
    },

    async handlePayUnit() {
      this.loading = true
      try {
        const { _id: contractId } = this.contract
        const { _id: unitId, paid, isPaid } = this.editUnit

        const body = { remove: isPaid }

        if (!isPaid)
          Object.assign(body, {
            payoffPrice: this.isForCash
              ? this.editUnit.amount
              : this.editUnit.payoff,
            paid,
          })

        await service.payUnit(contractId, unitId, body)
        this.handleCloseUnitPayDialog()
        await this.fetchItem()
      } catch (error) {
        console.error('Contracts handlePayUnit()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    prepareForm() {
      const formData = new FormData()

      for (const key in this.form)
        if (this.form[key]) {
          switch (key) {
          case 'file':
            if (typeof this.form.file == 'string')
              formData.append('file', this.form.file)
            else formData.append('file', this.form.file, this.form.file.name)
            break

          case 'bank':
          case 'units':
          case 'monthlyPayments':
            formData.append(key, JSON.stringify(this.form[key]))
            break

          default:
            formData.append(key, this.form[key])
            break
          }
        }

      return formData
    },

    async handlyReady() {
      try {
        this.loading = true
        await service.ready(this.contract._id)
        this.fetchItem()
        this.readyDialog = false
      } catch (error) {
        console.error('ContractDetails handlyReady()', error)
      } finally {
        this.loading = false
      }
    },

    async handlePayBalloon() {
      this.loading = true

      try {
        await service.payBalloon(this.contract._id)
        await this.fetchItem()
        this.balloonDialog = false
      } catch (error) {
        console.error('Contracts handlePayBalloon()', error)
      } finally {
        this.loading = false
      }
    },

    resetForm() {
      this.form = Object.assign({}, DEFAULT_ITEM)
    },

    close() {
      this.resetForm()
    },

    async save() {
      this.loading = true
      const formData = this.prepareForm()

      try {
        await service.editContract(this.contract._id, formData)
        await this.fetchItem()
        this.close()
      } catch (error) {
        console.error('Contracts save()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },
    monthlyPaymentsFilterChanged(v) {
      this.monthlyPaymentsFilter = v
    }
  },
}
</script>

<style lang="scss">
.ContractDetails {
  overflow-x: scroll;
  height: calc(100vh - 72px);

  &--green {
    background: #32cd32;
  }

  &--green-default {
    background: #32cd32;
    cursor: default;
  }

  &--red {
    background: #ee4b2b;
  }

  &--orange {
    background: #ff9800;
  }

  table {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #dddddd;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
    }
  }

  &__container {
    width: max-content;
    padding: 1px;
    display: flex;
    height: 100%;

    & > * {
      height: 100%;
      overflow-y: scroll;
    }

    & > *:not(:last-child) {
      margin-right: 32px;
    }
  }

  &__details {
    min-width: 480px;
    max-width: 540px;
    padding: 1px;
  }

  &__border-bottom {
    border-bottom: 3px solid #292AA3 !important;
  }
}
</style>
