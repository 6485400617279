<template>
  <div class="Contracts">
    <a
        style="padding: 20px; font-size: 20px"
        class="Contracts__id-col"
        @click="goBack()"
    >
      Back to {{ backToWhat() }}
    </a>
    <v-data-table
      style="padding-top: 20px"
      :loading="loading"
      :headers="headers"
      :items="items"
      :options="options"
      :footer-props="footerProps"
      :server-items-length="totalItems"
      @update:options="handleOptionsChange"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat height="30" class="mb-4">
          <v-toolbar-title class="mr-1">Contracts</v-toolbar-title>
          <v-btn
            :disabled="!permissions.ACCOUNTING_EDIT_ALL || loading"
            small
            color="primary"
            dark
            @click="handleAddNewItem"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
          <v-divider class="mx-4" inset vertical></v-divider>
          <table-filters :filterable-fields="tableFilters" table-key="bank-contracts" @change="onFiltersChange"></table-filters>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:item.id="{ item }">
        <td
            style="text-align: center"
            :style="{ background: item.terminated ? '#F44336' : '' }">
          <router-link
              class="Contracts__id-col"
              :to="getDetailsLink(item)"
          >
            {{ item.id }}
          </router-link>
        </td>
      </template>

      <template v-slot:item.file="{ item }">
        <td
            style="text-align: center"
            :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history :contractId="item._id" type="FILE_CHANGE">
            <button v-if="item.file" @click="handleOpenPDF(item.file)">
              <v-icon>mdi-file</v-icon>
            </button>
          </field-history>
        </td>
      </template>

      <template v-slot:item.date="{ item }">
        <td
            style="text-align: center"
            :style="{ background: item.terminated ? '#F44336' : '' }">
          {{ formatDate(item.date) }}
        </td>
      </template>

      <template v-slot:item.contractUnits="{ item }">
        <td
          :style="style(item.unitsType)"
          @click="handleOpenDetailsDialog(item)"
        >
          <v-icon v-for="i in getUnitIcons(item.unitsType)" :key="i">{{
            i
          }}</v-icon>
        </td>
      </template>

      <template v-slot:item.note="{ item }">
        <td
            style="text-align: center"
            :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history :contractId="item._id" type="NOTE_CHANGE">
            <span @click="handleOpenNoteDialog(item)">
              {{ item.note }}
            </span>
          </field-history>
        </td>
      </template>

      <template v-slot:item.unitsCount="{ item }">
        <td style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          {{ getUnitsText(item) }}
        </td>
      </template>

      <template v-slot:item.bank="{ item }">
        <td style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history
            :contractId="item._id"
            type="BANK_CHANGE"
            item-value="name"
          >
            {{ item.bank && item.bank.name }}
          </field-history>
        </td>
      </template>

      <template v-slot:item.monthlyPayment="{ item }">
        <td
          v-if="!isForCash(item.unitsType)"
          style="text-align: center"
          :style="{ background: item.terminated ? '#F44336' : '' }"
          :class="{
            'Contracts--green': isMonthPaid(item),
            'Contracts--red': isOverdue(item),
          }"
          @click="handleOpenMonthlyPayment(item)"
        >
          ${{ getAmount(item) }}
        </td>
      </template>

      <template v-slot:item.owningBalance="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          {{ item.owningBalance && `$${item.owningBalance.toFixed(2)}` }}
        </td>
      </template>

      <template v-slot:item.principalAmount="{ item }">
        <td style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history
            v-if="isForCash(item.unitsType)"
            :contractId="item._id"
            type="CASH_PRICE_CHANGE"
          >
            {{ item.cashPrice ? `$${item.cashPrice}` : '' }}
          </field-history>
          <field-history v-else :contractId="item._id" type="LOAN_AMOUNT_CHANGE">
            {{ item.principalAmount ? `$${item.principalAmount}` : '' }}
          </field-history>
        </td>
      </template>

      <template v-slot:item.downPayment="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history :contractId="item._id" type="DOWN_PAYMENT_CHANGE">
            {{ item.downPayment ? `$${item.downPayment}` : '' }}
          </field-history>
        </td>
      </template>

      <template v-slot:item.balloonPayment="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history
            v-if="!isForCash(item.unitsType)"
            :contractId="item._id"
            type="BALLOON_PAYMENT_CHANGE"
            columnNameShowHistory="balloonPayment"
          >
            {{ item.balloonPayment ? `$${item.balloonPayment.amount}` : '' }}
          </field-history>
        </td>
      </template>

      <template v-slot:item.interest="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history
            v-if="!isForCash(item.unitsType)"
            :contractId="item._id"
            type="INTEREST_CHANGE"
          >
            {{ item.interest }}%
          </field-history>
        </td>
      </template>

      <template v-slot:item.company="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history :contractId="item._id" type="COMPANY_CHANGE">
            {{ item.company }}
          </field-history>
        </td>
      </template>

      <template v-slot:item.paymentPeriod="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          <field-history
            v-if="!isForCash(item.unitsType)"
            :contractId="item._id"
            type="PAYMENT_PERIOD_CHANGE"
          >
            {{ item.paymentPeriod }}
          </field-history>
        </td>
      </template>

      <template v-slot:item.lastPaymentDate="{ item }">
        <td v-if="!isForCash(item.unitsType)" style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          {{ getLastPaymentDate(item.monthlyPayments) }}
        </td>
      </template>

      <template v-slot:item.dueDate="{ item }">
        <td style="text-align: center" :style="{ background: item.terminated ? '#F44336' : '' }">
          {{ formatDate(item.dueDate) }}
        </td>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <units-form
            v-if="permissions.ACCOUNTING_EDIT_ALL"
            :item="item"
            @submit-units="(newUnits) => handleSubmitUnits(item, newUnits)"
          />
          <v-icon
            small
            class="mr-2"
            color="green"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            :disabled="!permissions.ACCOUNTING_EDIT_ALL"
            color="red"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>

    <!-- Main form dialog -->
    <v-dialog v-model="dialog" max-width="768px" @click:outside="close">
      <v-card>
        <v-form @submit.prevent="save">
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text v-if="dialog">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="form.id"
                    :label="this.$route.query.isPaidWithCash ? 'Invoice ID*' : 'Contract ID*'"
                    prepend-icon="mdi-identifier"
                    :rules="[rules.required]"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu
                    ref="dateMenu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.date"
                        :disabled="!allowMainDataEdit"
                        label="Contract date*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :value="form.date"
                      @input="
                        (v) => {
                          $refs.dateMenu.save(v);
                          dateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="form.unitsType"
                    :items="unitsTypeOptions"
                    prepend-icon="mdi-list-status"
                    label="Select units type*"
                  >
                    <template v-slot:item="{ item }">
                      <v-badge :color="item.color" inline left>
                        {{ item.text }}
                      </v-badge>
                    </template>
                  </v-select>
                </v-col>

                <v-col v-if="!isPaidWithCash" md="4">
                  <v-menu
                    ref="firstPaymentDateMenu"
                    v-model="firstPaymentDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="form.firstPaymentDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.firstPaymentDate"
                        :disabled="!allowMainDataEdit"
                        label="First payment date*"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :value="form.firstPaymentDate"
                      @input="
                        (v) => {
                          $refs.firstPaymentDateMenu.save(v);
                          firstPaymentDateMenu = false;
                        }
                      "
                      no-title
                      scrollable
                    />
                  </v-menu>
                </v-col>

                <v-col :cols="isPaidWithCash ? 6 : 4">
                  <v-text-field
                    v-if="typeof form.file === 'string'"
                    v-model="form.file"
                    clearable
                    readonly
                    label="File"
                  />
                  <v-file-input
                    v-else
                    v-model="form.file"
                    label="File"
                    accept="application/pdf"
                  />
                </v-col>

                <v-col :cols="isPaidWithCash ? 6 : 4">
                  <v-select
                    v-model="form.bank"
                    :rules="[rules.required]"
                    :items="
                      banks.filter((b) =>
                        isPaidWithCash ? b.is_dealer : !b.is_dealer
                      )
                    "
                    :label="
                      isPaidWithCash ? 'Select a dealer' : 'Select a bank'
                    "
                    item-text="name"
                    return-object
                    prepend-icon="mdi-bank"
                  />
                </v-col>

                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-text-field
                    v-model.number="form.principalAmount"
                    prepend-icon="mdi-currency-usd"
                    :rules="[rules.required]"
                    :disabled="!allowMainDataEdit"
                    type="number"
                    min="0"
                    label="Principal amount*"
                  />
                </v-col>
                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-text-field
                    v-model.number="form.interest"
                    :rules="[rules.required, rules.biggerThanZero]"
                    :disabled="!allowMainDataEdit"
                    prepend-icon="mdi-percent"
                    type="number"
                    min="0"
                    label="Interest*"
                  />
                </v-col>
                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-text-field
                    v-model.number="form.paymentPeriod"
                    :rules="[rules.required, rules.integer]"
                    prepend-icon="mdi-clock"
                    type="number"
                    min="1"
                    step="1"
                    label="Payment period (months)*"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="form.downPayment"
                    :disabled="!allowMainDataEdit"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                    min="0"
                    label="Down payment"
                  />
                </v-col>

                <v-col v-if="isPaidWithCash" cols="12" md="4">
                  <v-text-field
                    v-model.number="form.cashPrice"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                    min="0"
                    label="Cash price*"
                  />
                </v-col>

                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-text-field
                    v-model.number="form.balloonPayment"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                    min="0"
                    label="Balloon payment"
                  />
                </v-col>

                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-text-field
                    v-model.number="form.monthlyPayment"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                    min="0"
                    :label="paymentLabel"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model.number="form.datSecurityDeposit"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                    min="0"
                    label="Dat security deposit"
                  />
                </v-col>

                <v-col cols="12" md="4">
                  <v-checkbox v-model="form.isConvoy" label="Is convoy?" />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-if="form.isConvoy"
                    v-model="form.company"
                    :rules="[rules.required]"
                    prepend-icon="mdi-domain"
                    label="Convoy*"
                    clearable
                  />
                  <v-select
                    v-else
                    v-model="form.company"
                    :items="companiesOptions"
                    :rules="[rules.required]"
                    prepend-icon="mdi-domain"
                    label="Company*"
                    clearable
                  />
                </v-col>



                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-text-field
                      v-model="form.unitsNumber"
                      label="Units Number"
                      prepend-icon="mdi-numeric-9"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-checkbox
                    v-model="form.pastPaymentsPaid"
                    label="Set past months as paid"
                  />
                </v-col>

                <v-col v-if="!isPaidWithCash" cols="12" md="4">
                  <v-checkbox
                    v-model="form.quartalPayment"
                    label="Set quartal payment"
                  />
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="form.note"
                    prepend-icon="mdi-information-outline"
                    label="Note"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
            <v-btn
              type="submit"
              color="blue darken-1"
              :disabled="submitDisabled"
              text
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Monthly payment dialog -->
    <v-dialog
      v-model="monthlyPaymentDialog"
      max-width="540px"
      @click:outside="closeMonthlyPayment"
    >
      <v-card v-if="editMonthlyPayment">
        <v-card-title class="text-h5"
          >Edit payment number {{ getPaymentIndex(editMonthlyPayment) }} for
          contract ID: {{ editMonthlyPayment.id }}</v-card-title
        >

        <v-card-text>
          <v-alert v-if="isOverdue(editMonthlyPayment)" type="warning"
            >Overdue!</v-alert
          >

          <v-row>
            <v-col cols="6">
              <v-text-field
                :value="getPaymentToDisplayDate(editMonthlyPayment)"
                disabled
                label="Due date"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="getAmount(editMonthlyPayment)"
                disabled
                label="Amount"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="getMonthlyPaymentsRemaining(editMonthlyPayment)"
                disabled
                label="Monthly payments remaining"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                :value="editMonthlyPayment.owningBalance.toFixed(2)"
                disabled
                label="Remaining debt"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeMonthlyPayment"
            >Close</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="toggleInstallmentPaid(editMonthlyPayment)"
            >{{
              isMonthPaid(editMonthlyPayment) ? 'Set as unpaid' : 'Set as paid'
            }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
      @click:outside="closeDelete"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Details dialog -->
    <v-dialog
      v-model="detailsDialog"
      max-width="768px"
      @click:outside="handleCloseDetails"
    >
      <v-card>
        <v-card-title class="text-h5"
          >Units for contract:<strong class="ml-2 text-decoration-underline">{{
            detailsId
          }}</strong>
        </v-card-title>

        <v-card-text v-if="details">
          <v-text-field v-model="details.makes" label="Makes" disabled />
          <v-text-field v-model="details.models" label="Models" disabled />

          <div class="d-flex align-center flex-wrap">
            <v-chip
              v-for="u in details.units"
              :key="u.vin_number"
              class="ma-1"
              dark
            >
              <v-icon left>{{ getIcon(u.type) }}</v-icon>
              {{ u.vin_number }}</v-chip
            >
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseDetails"
            >Close</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- PDF viewer dialog -->
    <v-dialog
      v-model="pdfDialog"
      max-width="90vw"
      @click:outside="handleClosePDFDialog"
    >
      <vue-pdf
        v-if="pdfURL"
        :source="pdfURL"
        style="height: 90vh; width: 90vw"
      />
    </v-dialog>

    <!-- Note dialog -->
    <v-dialog
      v-model="noteDialog"
      max-width="540px"
      @click:outside="handleCloseNoteDialog"
    >
      <v-card>
        <v-card-title />

        <v-card-text>
          <v-text-field
            v-model="editNote"
            label="Note"
            @keyup.enter="handleEditNote"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseNoteDialog"
            >Close</v-btn
          >
          <v-btn color="blue darken-1" text @click="handleEditNote"
            >{{ form.note ? 'Edit' : 'Add' }} note</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import moment from 'moment'
import { mapGetters } from 'vuex'
import service from '../../services/ContractService'
import yardService from '../../services/YardsServices'
import bankService from '../../services/BanksService'
import VuePDF from 'pdf-viewer-vue/dist/vue2-pdf-viewer'
import UnitsForm from './UnitsForm.vue'
import { generateYearsArr } from '../../utils'
import FieldHistory from './FieldHistory.vue'
import { HEADERS } from '../../utils/accountingConstants'
import TableFilters from '../Common/TableFilters.vue'

const REQUIRED = [
  'id',
  'bank',
  'principalAmount',
  'company',
  'interest',
  'paymentPeriod',
  'monthlyPayment',
  'unitsType',
  'firstPaymentDate',
]

const REQUIRED_FOR_CASH = ['id', 'bank', 'cashPrice', 'unitsType']

const DEFAULT_ITEM = {
  id: null,
  file: null,
  date: moment().format('YYYY-MM-DD'),
  bank: null,
  company: null,
  principalAmount: null,
  units: [],
  interest: null,
  paymentPeriod: 60,
  downPayment: 0,
  balloonPayment: 0,
  note: null,
  unitsType: null,
  isConvoy: false,
  pastPaymentsPaid: false,
  quartalPayment: false,
  cashPrice: null,
  dealer: null,
  datSecurityDeposit: null,
  monthlyPayment: null,
  unitsNumber: null
}

const footerProps = {
  'items-per-page-options': [10, 20, 30, 40, 50],
}

const VIN_ICONS = {
  'f-trucks': ['mdi-truck'],
  'rf-trucks': ['mdi-truck'],
  'c-trucks': ['mdi-truck'],
  'f-trailers': ['mdi-truck-trailer'],
  'rf-trailers': ['mdi-truck-trailer'],
  'c-trailers': ['mdi-truck-trailer'],
  'out-of-company': ['mdi-truck', 'mdi-truck-trailer'],
  'f-trucks-trailers': ['mdi-truck', 'mdi-truck-trailer'],
  'rf-trucks-trailers': ['mdi-truck', 'mdi-truck-trailer'],
  cars: ['mdi-cars'],
}

const time = (date) => new Date(date).getTime()

const DEALER_UNIT_OPTIONS = [
  { color: '#FFD300', text: 'Trucks for cash', value: 'c-trucks' },
  { color: '#3F51B5', text: 'Trailers for cash', value: 'c-trailers' },
]

const BANK_UNIT_OPTIONS = [
  { color: '#9C27B0', text: 'Financed trucks', value: 'f-trucks' },
  { color: '#9C27B0', text: 'Refinanced trucks', value: 'rf-trucks' },
  { color: '#FFA726', text: 'Financed trailers', value: 'f-trailers' },
  { color: '#FFA726', text: 'Refinanced trailers', value: 'rf-trailers' },
  { color: '#F44336', text: 'Out of company', value: 'out-of-company' },
  {
    color: '#FF007F',
    text: 'Financed trucks and trailers',
    value: 'f-trucks-trailers',
  },
  {
    color: '#FF007F',
    text: 'Refinanced trucks and trailers',
    value: 'rf-trucks-trailers',
  },
]

const TABLE_FILTERS = [
  {
    name: 'id',
    label: 'Name',
    type: 'text'
  },
  {
    name: 'company',
    label: 'Company',
    type: 'text'
  },
  {
    name: 'units.vin_number',
    label: 'VIN',
    type: 'text'
  },
  {
    name: 'date',
    label: 'Date',
    type: 'date'
  },
  {
    name: 'owningBalance',
    label: 'Amount',
    type: 'number'
  },
  {
    name: 'units.make.name',
    label: 'Make',
    type: 'text'
  },
  {
    name: 'units.model.name',
    label: 'Model',
    type: 'text'
  },
  {
    name: 'yard.name',
    label: 'Yard',
    type: 'text'
  }
]

export default {
  name: 'Contracts',

  components: { 'vue-pdf': VuePDF, UnitsForm, FieldHistory, TableFilters },

  data() {
    return {
      isPaidWithCash: this.$route.query.isPaidWithCash,
      unitsTypeOptions: this.$route.query.isPaidWithCash
        ? DEALER_UNIT_OPTIONS
        : BANK_UNIT_OPTIONS,
      loading: false,
      error: null,
      yearOptions: generateYearsArr(30),
      options: {
        page: 1,
        itemsPerPage: 30,
      },
      filters: null,
      tableFilters: [...TABLE_FILTERS],
      footerProps,
      totalItems: 0,
      dialog: false,
      dialogDelete: false,
      firstPaymentDateMenu: false,
      dateMenu: false,
      dateToMenu: false,
      dateFromMenu: false,
      editId: null,
      form: { ...DEFAULT_ITEM },
      selectedUnits: false,
      headers: this.$route.query.isPaidWithCash
        ? HEADERS.CONTRACT_WITH_CASH_HEADERS
        : HEADERS.CONTRACT_HEADERS,
      items: [],
      detailsDialog: false,
      detailsId: null,
      noteDialog: false,
      editNote: false,
      details: null,
      companiesOptions: ['Floyd Inc', 'Ego Express Inc', 'Floyd / Ego Express'],
      rules: {
        required: (value) => !!value || 'Required.',
        integer: (v) =>
          (Number(v) && Number.isInteger(Number(v))) || 'Must be an integer.',
        biggerThanZero: (v) =>
          (Number(v) && Number(v) > 0) || 'Must be greater than 0.',
      },
      yards: [],
      banks: [],
      pdfURL: null,
      pdfDialog: false,
      editMonthlyPayment: null,
      editMonthlyPayments: null,
      editMonthlyPaymentId: null,
      monthlyPaymentDialog: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['permissions']),
    bankId() {
      return this.$route.params.bankId
    },

    paymentLabel() {
      return this.form.quartalPayment ? 'Quartal payment*' : 'Monthly payment*'
    },

    vinFilter() {
      return this.$route.query.vin
    },

    formTitle() {
      return this.editId ? 'Edit contract' : 'Add contract'
    },

    submitDisabled() {
      for (const field of this.isForCash(this.form.unitsType)
        ? REQUIRED_FOR_CASH
        : REQUIRED)
        if (!this.form[field]) return true

      if (!this.isForCash(this.form.unitsType)) {
        const paymentPeriodValid =
          Number(this.form.paymentPeriod) &&
          Number.isInteger(Number(this.form.paymentPeriod))
        if (!paymentPeriodValid) return true

        const interestValid =
          Number(this.form.interest) && Number(this.form.interest) > 0
        if (!interestValid) return false
      }

      return false
    },

    allowMainDataEdit() {
      if (!this.editId) return true
      return !this.form.monthlyPayments.some((i) => i.paid)
    },
  },

  async created() {
    await this.fetchYards()
    await this.fetchBanks()
    await this.$store.dispatch('common/getAllCompanies')
  },

  methods: {
    isForCash(unitsType) {
      return ['c-trailers', 'c-trucks'].includes(unitsType)
    },

    async handleEditNote() {
      this.loading = true
      try {
        await service.editNote(this.editId, this.editNote)
        await this.fetchItems()
        this.handleCloseNoteDialog()
        this.resetForm()
      } catch (error) {
        console.error('ContractDetails handleEditNote()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    handleCloseNoteDialog() {
      this.editNote = null
      this.noteDialog = false
    },

    formatForDatePicker(date) {
      return moment(date).format('YYYY-MM-DD')
    },

    handleOpenNoteDialog(item) {
      const { _id, date, balloonPayment, ...rest } = item
      const body = {
        ...rest,
        balloonPayment: balloonPayment ? balloonPayment.amount : 0,
        date: this.formatForDatePicker(date),
      }

      if (
        Array.isArray(rest.monthlyPayments) &&
        rest.monthlyPayments.length > 0
      ) {
        Object.assign(body, {
          firstPaymentDate: this.formatForDatePicker(
            rest.monthlyPayments[0].dueDate
          ),
        })
      }

      this.form = Object.assign({}, body)
      this.editId = _id
      this.editNote = item.note
      this.noteDialog = true
    },

    getDetailsLink(item) {
      let url = `/contract-details/${item._id}`
      if (this.isForCash(item.unitsType)) url += '?paidWithCash=true'
      return url
    },

    async handleSubmitUnits(item, newUnits) {
      const { _id } = item
      this.loading = true
      try {
        await service.addUnits(_id, { units: newUnits })
        this.fetchItems()
      } catch (error) {
        console.warn('handleSubmitUnits()', error)
      } finally {
        this.loading = false
      }
    },

    handleDateToChanged(v) {
      this.dateToMenu = false
      this.$refs.dateToMenu.save(v)
      this.handleFiltersChanged()
    },

    handleDateFromChanged(v) {
      this.dateFromMenu = false
      this.$refs.dateFromMenu.save(v)
      this.handleFiltersChanged()
    },

    getPaymentToDisplay(arr) {
      const now = Date.now()

      const pastDues = arr.filter(({ dueDate }) => time(dueDate) < now)
      const unpaidPastDue = pastDues.find((p) => !p.paid)
      if (unpaidPastDue) return unpaidPastDue

      const [thisMonth] = arr.filter(({ dueDate }) => time(dueDate) > now)
      return thisMonth || arr[arr.length - 1]
    },

    getLastPaymentDate(arr) {
      const paidArr = arr.filter((m) => m.paid)
      const last = paidArr[paidArr.length - 1]
      if (last) return this.formatDate(last.paid)
      return null
    },

    getAmount({ monthlyPayments: arr }) {
      return this.getPaymentToDisplay(arr).amount.toFixed(2)
    },

    isMonthPaid({ monthlyPayments: arr }) {
      return this.getPaymentToDisplay(arr).paid
    },

    isOverdue({ monthlyPayments: arr }) {
      const { dueDate, paid } = this.getPaymentToDisplay(arr)
      return !paid && time(dueDate) < Date.now()
    },

    getPaymentIndex({ monthlyPayments: arr }) {
      const p = this.getPaymentToDisplay(arr)
      return arr.indexOf(p) + 1
    },

    getPaymentToDisplayDate({ monthlyPayments: arr }) {
      const p = this.getPaymentToDisplay(arr)
      return this.formatDate(p.dueDate)
    },

    async fetchYards() {
      try {
        const { data } = await yardService.getAllYards('ALL')
        if (Array.isArray(data))
          this.yards = data.map((y) => ({ text: y.name, value: y._id }))
      } catch (error) {
        console.error('Contracts fetchYards()', error)
      }
    },

    async fetchBanks() {
      try {
        const { data } = await bankService.getAllBanks()
        if (Array.isArray(data))
          this.banks = data.map(({ name, _id, is_dealer }) => ({
            name,
            _id,
            is_dealer,
          }))
      } catch (error) {
        console.error('Contracts fetchBanks()', error)
      }
    },

    handleOpenPDF(fileUrl) {
      setTimeout(() => (this.pdfURL = fileUrl), 500)
      this.pdfDialog = true
    },

    handleClosePDFDialog() {
      this.pdfURL = null
      this.pdfDialog = false
    },

    handleOpenMonthlyPayment(item) {
      this.editMonthlyPayment = item
      this.monthlyPaymentDialog = true
    },

    closeMonthlyPayment() {
      this.editMonthlyPayment = null
      this.monthlyPaymentDialog = false
    },

    getMonthlyPaymentsRemaining({ monthlyPayments }) {
      return monthlyPayments.filter((m) => !m.paid).length
    },

    handleFiltersChanged: debounce(async function () {
      this.items = []
      await this.fetchItems()
    }, 200),

    onFiltersChange(filters) {
      this.filters = filters
      this.fetchItems()
    },

    async fetchItems() {
      this.loading = true
      try {
        const params = {
          ...this.options,
          filters: JSON.stringify(this.filters),
        }

        if (this.vinFilter) {
          params.vinNumber = this.vinFilter
        }

        const { data } = await service.getContracts(this.bankId, params)
        this.totalItems = data.total
        this.items = data.docs
      } catch (error) {
        console.error('Contracts fetchItems()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },



    handleDateFromPicked() {
      this.$refs.dateFromMenu.save(this.filters.dateFrom)
      this.handleFiltersChanged()
    },

    handleDateToPicked() {
      this.$refs.dateToMenu.save(this.filters.dateTo)
      this.handleFiltersChanged()
    },

    handleAddNewItem() {
      const bank = this.banks.find((b) => b._id === this.bankId)
      this.form.bank = bank
      this.dialog = true
    },

    async handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      await this.fetchItems()
    },

    getIcon(type) {
      switch (type) {
      case 'car':
        return 'mdi-car'
      case 'trailer':
        return 'mdi-truck-trailer'
      default:
        return 'mdi-truck'
      }
    },

    style(v) {
      const type = this.unitsTypeOptions.find((t) => v === t.value)
      return {
        textAlign: 'center',
        backgroundColor: type.color,
        fontWeight: 700,
      }
    },

    formatDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },

    getUnitIcons(unitsType) {
      return VIN_ICONS[unitsType] || []
    },

    getDifferentMakes(units) {
      const makes = units
        .filter((u) => u.make)
        .map((u) => (u.type === 'trailer' ? u.make : u.make.name))
      return [...new Set(makes)]
    },

    getDifferentModels(units) {
      const models = units.filter((u) => u.model).map((u) => u.model.name)
      return [...new Set(models)]
    },

    handleOpenDetailsDialog({ id, units }) {
      this.details = {
        makes: units && this.getDifferentMakes(units).join(', '),
        models: units && this.getDifferentModels(units).join(', '),
        units:
          units &&
          units.map((u) => ({ vin_number: u.vin_number, type: u.type })),
      }
      this.detailsId = id
      this.detailsDialog = true
    },

    handleCloseDetails() {
      this.details = null
      this.detailsId = null
      this.detailsDialog = false
    },

    editItem(item) {
      const { _id, date, balloonPayment, ...rest } = item
      const body = {
        ...rest,
        date: this.formatForDatePicker(date),
        balloonPayment: balloonPayment ? balloonPayment.amount : 0,
      }

      if (
        Array.isArray(rest.monthlyPayments) &&
        rest.monthlyPayments.length > 0
      ) {
        Object.assign(body, {
          firstPaymentDate: this.formatForDatePicker(
            rest.monthlyPayments[0].dueDate
          ),
        })
      }

      this.form = Object.assign({}, body)
      this.editId = _id
      this.dialog = true
    },

    async toggleInstallmentPaid({ _id, monthlyPayments }) {
      this.loading = true
      try {
        const { _id: monthId, paid } =
          this.getPaymentToDisplay(monthlyPayments)
        await service.editPayment(_id, monthId, paid)
        await this.fetchItems()
        this.closeMonthlyPayment()
      } catch (error) {
        console.error('Contracts toggleInstallmentPaid()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },

    deleteItem(item) {
      const { _id, date, balloonPayment, ...rest } = item
      const body = {
        ...rest,
        date: this.formatForDatePicker(date),
        balloonPayment: balloonPayment ? balloonPayment.amount : 0,
      }

      if (
        Array.isArray(rest.monthlyPayments) &&
        rest.monthlyPayments.length > 0
      ) {
        Object.assign(body, {
          firstPaymentDate: this.formatForDatePicker(
            rest.monthlyPayments[0].dueDate
          ),
        })
      }

      this.form = Object.assign({}, body)
      this.editId = _id
      this.dialogDelete = true
    },

    closeDelete() {
      this.resetForm()
      this.dialogDelete = false
    },

    async deleteItemConfirm() {
      this.loading = true
      try {
        await service.deleteContract(this.editId)
        await this.fetchItems()
      } catch (error) {
        console.error('Contracts deleteItemConfirm()', error)
        this.error = true
      } finally {
        this.loading = false
        this.closeDelete()
      }
    },

    resetForm() {
      this.form = Object.assign({}, DEFAULT_ITEM)
      this.editId = null
    },

    close() {
      this.resetForm()
      this.dialog = false
    },

    getUnitsText({ units, unitsType }) {
      let text
      const truckUnits =
        units && units.filter((u) => u.type === 'truck' && !u.paid ).length
      const trailerUnits =
        units && units.filter((u) => u.type === 'trailer' && !u.paid).length
      const carUnits = units && units.filter((u) => u.type === 'car').length

      switch (unitsType) {
      case 'f-trucks':
      case 'rf-trucks':
      case 'c-trucks':
        text = truckUnits
        break

      case 'f-trailers':
      case 'rf-trailers':
      case 'c-trailers':
        text = trailerUnits
        break

      case 'out-of-company':
        text = `${truckUnits} / ${trailerUnits} / ${carUnits}`
        break

      case 'f-trucks-trailers':
      case 'rf-trucks-trailers':
        text = `${truckUnits} / ${trailerUnits}`
        break

      case 'cars':
        text = carUnits
        break

      default:
        break
      }

      return text
    },

    prepareForm() {
      const formData = new FormData()
      const data = {}

      for (const key in this.form)
        switch (key) {
        case 'file':
          if (typeof this.form.file == 'string')
            formData.append('file', this.form.file)
          else if (this.form.file)
            formData.append('file', this.form.file, this.form.file.name)
          break

        default:
          Object.assign(data, { [key]: this.form[key] })
          break
        }

      formData.append('data', JSON.stringify(data))
      return formData
    },

    async save() {
      this.loading = true

      const formData = this.prepareForm()

      try {
        if (this.editId) await service.editContract(this.editId, formData)
        else await service.addContract(formData)
        await this.fetchItems()
        this.close()
      } catch (error) {
        console.error('Contracts save()', error)
        this.error = true
      } finally {
        this.loading = false
      }
    },
    goBack() {
      const currentRoute = this.$route // Get the current route
      const isPaidWithCash = currentRoute.query.isPaidWithCash === 'true'

      if (isPaidWithCash) {
        this.$router.push('/banks?isDealer=true')
      } else {
        this.$router.push('/banks') // If no previous route exists, go to '/banks'
      }
    },
    backToWhat() {
      const currentRoute = this.$route // Get the current route
      const isPaidWithCash = currentRoute.query.isPaidWithCash === 'true'

      return isPaidWithCash ? 'Dealers' : 'Banks'
    }
  },
}
</script>

<style lang="scss">
.Contracts {
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: 28px;
    padding: 0;
  }

  &--green {
    background: #32cd32;
  }

  &--red {
    background: #ee4b2b;
  }

  table {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
  }

  tr:hover {
    cursor: pointer;
  }

  table th + th {
    border-left: 1px solid #dddddd;
  }
  table td + td {
    border-left: 1px solid #dddddd;
  }

  table {
    th {
      border-top: 1px solid #453232;
      padding: 0 6px !important;
    }

    td {
      padding: 0 6px !important;
      position: relative;
    }
  }

  &__id-col {
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
    color: black;
  }

  th:nth-child(1),
  tr > td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 4 !important;
    background: white;
    border-right: 2px solid #cfcfcf;
  }

  tr:hover > td:nth-child(1) {
    background: #eeeeee;
  }
}
</style>
